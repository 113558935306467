import { useEffect, useState } from "react";
import { ChibbityQuestion } from "../../data/DataModels";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { PlaySource } from "../../data/Enums";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import {
  setTriviaData,
  UserAnswerHistory_DEPRECATED,
} from "../DEPRECATED_redux/dailyTriviaSlice";
// import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { ALLOWED_GUESSES_CNT } from "../../data/AppConst";
import { useDispatch, useSelector } from "react-redux";

import {
  addQuestionAnsweredID,
  addTriviaQuestions,
  fetchRandomTriviaData,
  incrementRandomQuestionIdx,
  incrementRandomQuestionsVisible,
} from "../DEPRECATED_redux/randomTriviaSlice";
import axios from "axios";
import { AppDispatch, RootState } from "../DEPRECATED_redux/store";
// import styles from "./PlayCard.module.css";


// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: "#fff",
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
//   flexGrow: 1,
//   ...theme.applyStyles("dark", {
//     backgroundColor: "#1A2027",
//   }),
//   wordWrap: "break-word",
// }));

interface AnswerCardProps {
  source: PlaySource;
  question: ChibbityQuestion;
  singleQuestionUserAnswers: UserAnswerHistory_DEPRECATED | undefined;
  incorrectInputAnimationActive: boolean;
  correctInputAnimationActive: boolean;
  closeInputAnimationActive: boolean;
  userAnsweredCorrectly: boolean | null;
  gaveUp: boolean;
  // closestAnswer: string | null;
}

export default function AnswerCard(props: AnswerCardProps) {
  const [questionRatedGood, setQuestionRatedGood] = useState<boolean | null>(
    null
  );

  const [feedbackFinished, setFeedbackFinished] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();
  const queryParams = useSelector(
    (state: RootState) => state.randomTrivia.queryParams
  );
  const randomTriviaIdx = useSelector(
    (state: RootState) => state.randomTrivia.randomTriviaIdx
  );
  const randomTriviaQuestions = useSelector(
    (state: RootState) => state.randomTrivia.randomTriviaQuestions
  );

  // const answeredQuestions = useSelector(
  //   (state: RootState) => state.randomTrivia.answeredQuestions
  // );

  const currQuestionAnsweredObj = useSelector(
    (state: RootState) =>
      state.randomTrivia.answeredQuestions[props.question.question_id] ?? null
  );

  const display_answer =
    props.source === PlaySource.DAILY
      ? props.singleQuestionUserAnswers?.closestAnswer
      : currQuestionAnsweredObj?.closestAnswer;

  const fetchAndHandleTrivia = async ({
    fetch_cnt,
    override_questions,
  }: {
    fetch_cnt: number;
    override_questions?: boolean;
  }) => {
    const resultAction = await dispatch(
      fetchRandomTriviaData({ fetch_cnt: fetch_cnt ?? 5, ...queryParams })
    );
    if (fetchRandomTriviaData.fulfilled.match(resultAction)) {
      const newQuestions = resultAction.payload;

      if (override_questions) {
        

        dispatch(
          setTriviaData([
            ...randomTriviaQuestions.slice(0, randomTriviaIdx + 1),
            ...newQuestions,
          ])
        );
      } else {
        

        dispatch(addTriviaQuestions(newQuestions));
      }
    }
    
  };

  useEffect(() => {
    if (
      randomTriviaIdx === randomTriviaQuestions.length - 1 &&
      questionRatedGood !== null &&
      feedbackFinished
    ) {
      fetchAndHandleTrivia({ fetch_cnt: 5 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [randomTriviaIdx, feedbackFinished, questionRatedGood]);

  useEffect(() => {
    if (questionRatedGood !== null && feedbackFinished) {
      setTimeout(() => {
        // if (!answeredQuestions[props.question.question_id]) {
        dispatch(
          addQuestionAnsweredID({
            question_id: props.question.question_id,
            correct: props.userAnsweredCorrectly ?? false,
            closestAnswer: null,
          })
        );
        // }
        dispatch(incrementRandomQuestionIdx());
        dispatch(incrementRandomQuestionsVisible());

      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionRatedGood, feedbackFinished]);

  const postQuestionRating = (
    quality_rating: number,
    difficulty_rating: number,
    bad_question_reason: string
  ) => {
    axios.post("https://api.triviaowls.xyz/v1/rate", {
      question_id: randomTriviaQuestions[randomTriviaIdx].question_id,
      quality_rating,
      difficulty_rating,
      bad_question_reason,
    });

    // const qs_copy = votedQs.add(
    //   randomTriviaQuestions[randomTriviaIdx].question_id
    // );

    // setVotedQs(qs_copy);
    // if (quality_rating !== 0) {
    //   setQuestionRatedGood(true);
    // }
    // if (quality_rating === -1) {
    //   setQuestionRatedGood(false);
    // }
    if (difficulty_rating !== 0) {
      setFeedbackFinished(true);
    }
    if (bad_question_reason !== "") {
      setFeedbackFinished(true);
    }
  };

  useEffect(() => {
    setQuestionRatedGood(null);
    setFeedbackFinished(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [randomTriviaIdx]);

  const question_rating_component = (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          position: "relative",
          paddingTop: "10px",
          flexWrap: "wrap",
        }}
      >
        {questionRatedGood === null && !feedbackFinished ? (
          <>
            <CardActions>
              <Button
                color="info"
                variant="contained"
                size="small"
                onClick={() => setQuestionRatedGood(true)}
                classes={
                  {
                    // root: "custom-root-class",
                    // outlined: "custom-outlined-class",
                    // text: "custom-text-class",
                    // colorSuccess: "custom-color-success",
                  }
                }
              >
                Good question!
              </Button>
            </CardActions>
            <CardActions>
              <Button
                color="warning"
                variant="contained"
                size="small"
                onClick={() => setQuestionRatedGood(false)}
                classes={
                  {
                    // root: "custom-root-class",
                    // outlined: "custom-outlined-class",
                    // text: "custom-text-class",
                    // colorSuccess: "custom-color-success",
                  }
                }
              >
                Not great...
              </Button>
            </CardActions>
          </>
        ) : questionRatedGood === true && !feedbackFinished ? (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "space-around",
                position: "relative",
                // paddingTop: "10px",
                width: "100%",
                // flexWrap: "wrap",
              }}
            >
              <CardActions>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  fullWidth={true}
                  onClick={() => postQuestionRating(1, 1, "")}
                >
                  Very Easy
                </Button>
              </CardActions>
              <CardActions>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  fullWidth={true}
                  onClick={() => postQuestionRating(1, 2, "")}
                >
                  Easy
                </Button>
              </CardActions>
              <CardActions>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  fullWidth={true}
                  onClick={() => postQuestionRating(1, 3, "")}
                >
                  Medium
                </Button>
              </CardActions>
              <CardActions>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  fullWidth={true}
                  onClick={() => postQuestionRating(1, 4, "")}
                >
                  <Typography variant="body2" component="div">
                    Hard
                  </Typography>
                </Button>
              </CardActions>
              <CardActions>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  fullWidth={true}
                  onClick={() => postQuestionRating(1, 5, "")}
                >
                  <Typography variant="body2" component="div">
                    Very Hard
                  </Typography>
                </Button>
              </CardActions>
            </Box>
          </>
        ) : questionRatedGood === false && !feedbackFinished ? (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "space-around",
                position: "relative",
                // paddingTop: "10px",
                width: "100%",
                // flexWrap: "wrap",
              }}
            >
              <CardActions>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  fullWidth={true}
                  onClick={() => postQuestionRating(-1, 0, "incorrect")}
                >
                  Answer is incorrect
                </Button>
              </CardActions>
              <CardActions>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  fullWidth={true}
                  onClick={() => postQuestionRating(-1, 0, "poorly_worded")}
                >
                  Question poorly worded
                </Button>
              </CardActions>
              <CardActions>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  fullWidth={true}
                  onClick={() => postQuestionRating(-1, 0, "multiple_answers")}
                >
                  More than one answer
                </Button>
              </CardActions>
              <CardActions>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  fullWidth={true}
                  onClick={() =>
                    postQuestionRating(-1, 0, "answer_in_question")
                  }
                >
                  Answer stated in question
                </Button>
              </CardActions>
              <CardActions>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  fullWidth={true}
                  onClick={() =>
                    postQuestionRating(-1, 0, "does_not_make_sense")
                  }
                >
                  Question/answer does not make sense
                </Button>
              </CardActions>
            </Box>
          </>
        ) : feedbackFinished ? (
          <>Thanks!</>
        ) : (
          <></>
        )}
      </Box>
    </>
  );

  const correct_answer_component = (
    <>
      <br />
      <div
        style={{
          width: "300px",
          margin: "0 auto",
          borderRadius: "20px",
          textAlign: "left",
          boxShadow: "0 0 24px 0px rgba(0, 255, 0, .2)",
        }}
        // className={
        //   props.correctInputAnimationActive
        //     ? styles.correct_animation
        //     : styles.normal_no_animation
        // }
      >
        <Card style={{ padding: "10px", borderRadius: "20px" }}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                top: "0px",
                position: "relative",
              }}
            >
              <Typography
                gutterBottom
                sx={{ color: "text.secondary", fontSize: 14 }}
              >
                You got it!{" "}
              </Typography>
              <DoneIcon
                color="success"
                style={{
                  position: "relative",
                  top: "-2.5px",
                  right: "-2px",
                }}
              />
            </Box>
            <Typography variant="h5" component="div">
              {display_answer}
            </Typography>
            <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
              <br />
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {props.question.question_tags.map((item, i) => {
                return (
                  <div key={item + i}>
                    {item.replace("_", " ") +
                      (i < props.question.question_tags.length - 1
                        ? ", "
                        : "")}
                  </div>
                );
              })}
            </Typography>
          </CardContent>
        </Card>
      </div>
    </>
  );

  const guess_counter_placeholder_component = (
    <>
      <Typography variant="h5" color="textSecondary">
        {ALLOWED_GUESSES_CNT -
          (props.singleQuestionUserAnswers?.answerGuesses.length ?? 0)}{" "}
        guess
        {ALLOWED_GUESSES_CNT -
          (props.singleQuestionUserAnswers?.answerGuesses.length ?? 0) ===
        1
          ? ""
          : "es"}{" "}
        left
      </Typography>
    </>
  );

  const random_gave_up_component = (
    <>
      <br />
      <div
        style={{
          width: "300px",
          margin: "0 auto",
          borderRadius: "20px",
          textAlign: "left",
          boxShadow: "0 0 24px 0px rgba(255, 0, 0, .2)",
        }}
        // className={
        //   props.incorrectInputAnimationActive
        //     ? styles.incorrect_animation
        //     : styles.normal_no_animation
        // }
      >
        <Card style={{ padding: "10px", borderRadius: "20px" }}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                top: "0px",
                position: "relative",
              }}
            >
              <Typography
                gutterBottom
                sx={{ color: "text.secondary", fontSize: 14 }}
              >
                Gave up eh?
              </Typography>
              <CloseIcon
                color="error"
                style={{
                  position: "relative",
                  top: "-2.5px",
                  right: "0px",
                }}
              />
            </Box>

            <Typography variant="h5" component="div">
              {props.question.accepted_correct_answers[0]}
            </Typography>
            <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
              <br />
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {props.question.question_tags.map((item, i) => {
                return (
                  <div key={item + i}>
                    {item.replace("_", " ") +
                      (i < props.question.question_tags.length - 1
                        ? ", "
                        : "")}
                  </div>
                );
              })}
            </Typography>
          </CardContent>
          {question_rating_component}
        </Card>
      </div>
    </>
  );

  const daily_gave_up_or_wrong_component = (
    <>
      <br />
      <div
        // className={
        //   source === PlaySource.RANDOM && correctInputAnimationActive
        //     ? styles.correct_animation
        //     : styles.normal_no_animation
        // }
        style={{
          width: "300px",
          margin: "0 auto",
          borderRadius: "20px",
          textAlign: "left",
          boxShadow: "0 0 24px 0px rgba(255, 0, 0, .2)",
        }}
        // className={
        //   props.incorrectInputAnimationActive
        //     ? styles.incorrect_animation
        //     : styles.normal_no_animation
        // }
      >
        <Card style={{ padding: "10px", borderRadius: "20px" }}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                top: "0px",
                position: "relative",
              }}
            >
              <Typography
                gutterBottom
                sx={{ color: "text.secondary", fontSize: 14 }}
              >
                All out of guesses!
              </Typography>
              <CloseIcon
                color="error"
                style={{
                  position: "relative",
                  top: "-2.5px",
                  right: "0px",
                }}
              />
            </Box>

            <Typography variant="h5" component="div">
              {props.question.accepted_correct_answers[0]}
            </Typography>
            <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
              <br />
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {props.question.question_tags.map((item, i) => {
                return (
                  <div key={item + i}>
                    {item.replace("_", " ") +
                      (i < props.question.question_tags.length - 1
                        ? ", "
                        : "")}
                  </div>
                );
              })}
            </Typography>
          </CardContent>
        </Card>
      </div>
    </>
  );

  const correct_answer_component_with_rating = (
    <>
      <br />
      <div
        style={{
          maxWidth: "500px",
          margin: "0 auto",
          borderRadius: "20px",
          textAlign: "left",
          boxShadow: "0 0 24px 0px rgba(0, 255, 0, .2)",
        }}
        // className={
        //   props.correctInputAnimationActive
        //     ? styles.correct_animation
        //     : styles.normal_no_animation
        // }
      >
        <Card style={{ padding: "10px", borderRadius: "20px" }}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                top: "0px",
                position: "relative",
              }}
            >
              <Typography
                gutterBottom
                sx={{ color: "text.secondary", fontSize: 14 }}
              >
                You got it!{" "}
              </Typography>
              <DoneIcon
                color="success"
                style={{
                  position: "relative",
                  top: "-2.5px",
                  right: "-2px",
                }}
              />
            </Box>
            <Typography variant="h5" component="div">
              {display_answer}
            </Typography>
            <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
              <br />
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {props.question.question_tags.map((item, i) => {
                return (
                  <>
                    {item.replace("_", " ") +
                      (i < props.question.question_tags.length - 1
                        ? ", "
                        : "")}
                  </>
                );
              })}
            </Typography>
            {question_rating_component}
          </CardContent>
        </Card>
      </div>
    </>
  );

  return (
    <div style={{ transition: "0s" }}>
      {props.singleQuestionUserAnswers?.answeredCorrectly ||
      props.userAnsweredCorrectly ? (
        props.source === PlaySource.RANDOM ? (
          correct_answer_component_with_rating
        ) : (
          correct_answer_component
        )
      ) : props.source === PlaySource.RANDOM && props.gaveUp ? (
        random_gave_up_component
      ) : props.source === PlaySource.DAILY &&
        (props.singleQuestionUserAnswers?.answeredCorrectly === false ||
          props.userAnsweredCorrectly === false) ? (
        daily_gave_up_or_wrong_component
      ) : props.source === PlaySource.DAILY ? (
        guess_counter_placeholder_component
      ) : currQuestionAnsweredObj?.answered === true ? (
        correct_answer_component
      ) : currQuestionAnsweredObj?.answered === false ? (
        daily_gave_up_or_wrong_component
      ) : (
        <></>
      )}
    </div>
  );
}
