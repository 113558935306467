import Button from "@mui/material/Button";
import {
  Box,
  Container,
  Paper,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { UserAnswerHistory } from "../../../redux_v2/dailyTriviaSlice";
import { useEffect, useState } from "react";

// import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import styles from "./DailyQuizTutorialDialog.module.css";
import { ALLOWED_GUESSES_CNT } from "../../../data/AppConst";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { Answer } from "../../../redux_v2/dailyTriviaSlice";
import {
  correct_scale,
  FeedbackStats,
  give_up_scale,
  guesses_scale,
} from "./DailyQuizFinishedUpsell";
import { setHasSeenTutorialCompleted } from "../../../redux_v2/globalStateSlice";
import {
  tutorial_dummy_answer_stack,
  tutorial_dummy_user_data,
  tutorial_dummy_wordle_string,
} from "./DailyQuizTutorialStaticDummyData";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  flexGrow: 1,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
  wordWrap: "break-word",
}));

const user_feedback_stats = (
  user_answers: UserAnswerHistory[]
): FeedbackStats[] => {
  let total_guesses = 0;
  let total_correct = 0;
  let total_given_up = 0;
  let total_hints = 0;
  // let perfect_game = false;

  for (const item of user_answers) {
    total_guesses += item.answerGuesses.length;
    if (item.answeredCorrectly) {
      total_correct++;
    }
    if (
      item.answeredCorrectly === false &&
      item.answerGuesses.length !== ALLOWED_GUESSES_CNT
    ) {
      total_given_up++;
    }
    if (item.hintAcitvated) {
      total_hints++;
    }
  }
  // if (total_correct === 5 && total_guesses === 5) {
  //   perfect_game = true;
  // }
  return [
    {
      label: "Correct",
      count: total_correct,
      emoji: correct_scale.get(total_correct) ?? "",
    },
    {
      label: "Guesses",
      count: total_guesses,
      emoji: guesses_scale.get(total_guesses) ?? "🥲",
    },
    {
      label: "Hints",
      count: total_hints,
      emoji: give_up_scale.get(total_hints) ?? "🥲",
    },
    {
      label: "Gave up",
      count: total_given_up,
      emoji: give_up_scale.get(total_given_up) ?? "",
    },
  ];
};

const stats = user_feedback_stats(tutorial_dummy_user_data);

export default function DailyQuizTutorialDialog() {
  const [currPage, setCurrPage] = useState<number>(1);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currPage === 4) {
      dispatch(setHasSeenTutorialCompleted());
    }
  }, [currPage, dispatch]);

  const onCopyShareText = () => {
    const date = new Date();
    const full_date =
      date.getMonth() +
      "/" +
      date.getDate() +
      "/" +
      `${date.getFullYear()}`.slice(2, 4);

    const extra_data = ["Daily Five", full_date];
    const converted_text = [
      ...extra_data,
      ...tutorial_dummy_wordle_string,
    ].join("\n");
    navigator.clipboard.writeText(converted_text).then(() => {
      setVisible(true);

      setTimeout(() => {
        setVisible(false);
      }, 1000);
    });
  };

  const handleButtonClick = () => {
    setCurrPage(currPage + 1);
  };

  const intro_component = (
    <Typography
      variant="body1"
      color="textPrimary"
      style={{
        margin: "10px",
        textWrap: "pretty",
        textAlign: "center",
      }}
    >
      The quiz is 5 short answer questions of increasing difficulty, refreshed
      each day at midnight PST.
      <br />
      <br />
      <br />
      For each question, you have 4 guesses to get the currect answer. Each
      guess will be marked:
      <br />
      <br />
      <Typography
        variant="body1"
        color="textPrimary"
        style={{
          //   margin: "10px",
          textWrap: "pretty",
          //   textAlign: "center",
          justifyContent: "center",
          margin: "0 auto",
          marginTop: "10px",
          width: "125px",
          left: "2%",
          //   margin: "0 auto",
          position: "relative",
        }}
      >
        <Box
          style={{
            textAlign: "left",
          }}
        >
          <CloseIcon color="error" fontSize="small" /> incorrect <br />
          <CloseIcon color="warning" fontSize="small" /> close <br />
          <DoneIcon color="success" fontSize="small" /> correct <br />
          <QuestionMarkIcon color="warning" fontSize="small" /> or as a hint
        </Box>
        <br />
        <br />
      </Typography>
    </Typography>
  );

  const demo_component = (
    <>
      <Typography
        variant="h6"
        color="textPrimary"
        style={{
          margin: "10px",
          textWrap: "pretty",
          textAlign: "center",
        }}
      >
        You can trade a guess for a hint, and see your previous guesses. Here's
        an example stack of guesses:
        <br />
        <br />
      </Typography>
      <Typography
        variant="h6"
        color="textSecondary"
        style={{
          margin: "10px",
          textWrap: "pretty",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            padding: "auto",
            typography: "body1",
            justifyContent: "center",
            width: "auto",
            minWidth: "20px",
            maxWidth: "300px",
            margin: "0 auto",
          }}
        >
          <Stack spacing={2} justifyContent={"center"}>
            In which Brazilian city can you find the iconic Sugarloaf Mountain?
            <br />
            {tutorial_dummy_answer_stack.map(
              (answer_item: Answer, i: number) => {
                return (
                  <Item
                    key={"test_answer_stack_" + i}
                    style={{
                      backgroundColor: answer_item.isCorrect
                        ? "primary"
                        : "secondary",
                    }}
                    //   className={
                    //     i === 0 && props.incorrectInputAnimationActive
                    //       ? styles.incorrect_animation
                    //       : i === 0 && props.correctInputAnimationActive
                    //         ? styles.correct_animation
                    //         : i === 0 && props.closeInputAnimationActive
                    //           ? styles.close_animation
                    //           : styles.normal_no_animation
                    //   }
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        {" "}
                        <div style={{ width: "10%" }}>
                          {answer_item.isCorrect === 1 ? (
                            <DoneIcon color="success" />
                          ) : answer_item.isCorrect === -1 ? (
                            <CloseIcon color="error" />
                          ) : answer_item.isCorrect === 0 ? (
                            <CloseIcon color="warning" />
                          ) : answer_item.isCorrect === 2 ? (
                            <QuestionMarkIcon color="warning" />
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>

                      <div
                        style={{
                          width: "80%",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        {answer_item.answerText}
                      </div>
                      <div
                        style={{
                          width: "10%",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        {(tutorial_dummy_answer_stack.length ?? 4) - i}
                      </div>
                    </div>
                  </Item>
                );
              }
            )}
          </Stack>
          <Typography
            variant="body1"
            color="textSecondary"
            style={{
              margin: "10px",
              textWrap: "pretty",
              textAlign: "center",
            }}
            // height={"100%"}
          >
            Hint: This city is famous for its carnival festival.
          </Typography>
        </Box>
        <br />
      </Typography>
    </>
  );

  return (
    <Container
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        maxWidth: "500px",
        alignItems: "center",
        // textAlign: "center",
        gap: { xs: 3, sm: 2 },
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        {" "}
        <Typography variant="h4" color="textPrimary">
          Tutorial
        </Typography>
      </div>
      <Box
        sx={{
          width: "100%",
          padding: "auto",
          typography: "body1",
          //   justifyContent: "center",
          maxWidth: "100%",
          minHeight: "10vh",
          justifyContent: "space-between",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          {" "}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {currPage === 1 ? (
              intro_component
            ) : currPage === 2 ? (
              demo_component
            ) : (
              <Typography
                variant="body1"
                color="textPrimary"
                style={{
                  margin: "10px",
                  textWrap: "pretty",
                  textAlign: "center",
                }}
              >
                Don't forget to share your score with friends!
                <br />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      color="textSecondary"
                      style={{
                        margin: "10px",
                        textWrap: "pretty",
                        textAlign: "center",
                      }}
                    >
                      You got 3 out of 5 questions!
                    </Typography>
                    <Stack
                      spacing={2}
                      justifyContent={"center"}
                      style={{ minWidth: "400px" }}
                    >
                      {stats.map((item, i) => {
                        return (
                          <Item
                            key={item.label + i}
                            style={{
                              backgroundColor: true ? "primary" : "secondary",
                              paddingLeft: "30px",
                              paddingRight: "30px",
                              width: "80%",
                              margin: "0 auto",
                              marginBottom: "10px",
                            }}
                          >
                            <Typography
                              variant="body1"
                              fontWeight={900}
                              color="textSecondary"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "row",
                              }}
                            >
                              <div style={{ minWidth: "30%" }}>
                                {item.label}
                              </div>
                              <div>{item.count}</div>
                              <div>{item.emoji}</div>
                            </Typography>
                          </Item>
                        );
                      })}
                    </Stack>
                    <br />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      <Typography variant="body1" color="textSecondary">
                        Click to share your score
                      </Typography>
                      {/* <Button style={{width: "0px"}}>
                  <ContentCopyIcon
                    style={{ position: "relative" }}
                  /> */}
                      {/* </Button> */}
                    </div>
                    <Box
                      sx={{
                        padding: "auto",
                        typography: "body1",
                        justifyContent: "center",
                        width: "auto",
                        minWidth: "150px",
                        maxWidth: "250px",
                        border: "1px solid black",
                        borderRadius: "20px",
                        margin: "0 auto",
                        backgroundColor: "grey",
                      }}
                      onClick={onCopyShareText}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "row",
                        }}
                      >
                        <Typography
                          variant="h6"
                          color="textPrimary"
                          textAlign={"left"}
                          style={{ position: "relative", margin: "10px" }}
                        >
                          {tutorial_dummy_wordle_string.map((item) => {
                            return (
                              <>
                                {item}
                                <br />
                              </>
                            );
                          })}
                        </Typography>
                      </div>
                    </Box>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        className={visible ? styles.visible : styles.hidden}
                        style={{ padding: "5px" }}
                      >
                        Copied to clipboard!
                      </Typography>
                    </div>
                  </div>
                </div>
              </Typography>
            )}
            {/* {stats.map((item, i) => {
                return (
                  <Item
                    key={item.label + i}
                    style={{
                      backgroundColor: true ? "primary" : "secondary",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      width: "80%",
                      margin: "0 auto",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontWeight={900}
                      color="textSecondary"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ minWidth: "30%" }}>{item.label}</div>
                      <div>{item.count}</div>
                      <div>{item.emoji}</div>
                    </Typography>
                  </Item>
                );
              })} */}

            <br />
            {/* <FlexBox dir="row"> */}
            {/* <Typography variant="h6" color="textPrimary">
                Click to share your score
              </Typography> */}
            {/* <Button style={{width: "0px"}}>
                <ContentCopyIcon
                  style={{ position: "relative" }}
                /> */}
            {/* </Button> */}
            {/* </FlexBox> */}
            {/* <Box
              sx={{
                padding: "auto",
                typography: "body1",
                justifyContent: "center",
                width: "auto",
                minWidth: "150px",
                maxWidth: "250px",
                border: "1px solid black",
                borderRadius: "20px",
                margin: "0 auto",
                backgroundColor: "grey",
              }}
              //   onClick={onCopyShareText}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                }}
              >
                <Typography
                  variant="h6"
                  color="textPrimary"
                  style={{ position: "relative", margin: "10px" }}
                >
                  {shareText.map((item) => {
                    return (
                      <>
                        {item}
                        <br />
                      </>
                    );
                  })}
                </Typography>
              </div>
            </Box> */}
            {/* <FlexBox dir="row"> */}
            {/* <Typography
                variant="h6"
                color="textPrimary"
                // className={visible ? styles.visible : styles.hidden}
                style={{ padding: "10px" }}
              >
                Copied to clipboard!
              </Typography> */}
            {/* </FlexBox> */}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          {" "}
          <Button variant="contained" onClick={handleButtonClick}>
            Next
          </Button>
        </div>
      </Box>
    </Container>
  );
}
