import { configureStore, Middleware } from "@reduxjs/toolkit";
import dailyTriviaReducer from "./dailyTriviaSlice";
import randomTriviaReducer from "./randomTriviaSlice";
import globalStateReducer from "./globalStateSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
// import { createMigrate } from "redux-persist";

// const migrations = {
//   1: (state: any) => {
//     return {
//       ...state,
//       randomTrivia: {
//         ...state.randomTrivia,
//         paginationData: state.randomTrivia?.paginationData || {
//           last_cursor: "",
//           page_idx: 0,
//           page_size: 0,
//           has_more: true,
//         },
//       },
//     };
//   },
// };
// const migrations = {
//   1: (state: any) => {
//     console.log("Resetting state for version mismatch");
//     return undefined; // Reset to initialState
//   },
// };

// const migrations = {
//   1: (state: any) => {
//     return undefined; // This will reset the state to initialState

//     // console.log("Running migration to version 1");
//     // console.log("Current state:", state);
//     // const migratedState = {
//     //   ...state,
//     //   paginationData: state?.paginationData || {
//     //     last_cursor: "",
//     //     page_idx: 0,
//     //     page_size: 0,
//     //     has_more: true,
//     //   },
//     // };
//     // console.log("Migrated state:", migratedState);
//     // return migratedState;
//   },
// };

const randomTriviaPersistConfig = {
  key: "randomTrivia",
  storage,
  version: 1, // Matches the migration version
  blacklist: [
    "loading",
    "error",
    "randomTriviaQuestions",
    "randomTriviaIdx",
    "answeredQuestionIDs",
    "questionsVisible",
    "paginationData",
    "queryParams",
  ],
  // migrate: createMigrate(migrations, { debug: true }),
};

const dailyTriviaPersistConfig = {
  key: "dailyTrivia",
  storage,
  blacklist: ["loading", "error"],
};

const globalStatePersistConfig = {
  key: "globalState",
  storage,
  blacklist: ["loading", "error"],
};

const resetStateOnNewDay: Middleware = (store) => (next) => (action) => {
  // Get the current date in Pacific Time (PT)
  const currentDate = new Intl.DateTimeFormat("en-US", {
    timeZone: "America/Los_Angeles",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(new Date());

  const formattedDate = currentDate.split("/").reverse().join("-");

  const persistedDate = localStorage.getItem("persistedDate");

  if (persistedDate && persistedDate !== formattedDate) {
    localStorage.removeItem("persist:dailyTrivia");
    localStorage.setItem("persistedDate", formattedDate);
    store.dispatch({ type: "dailyTrivia/resetState" });
  } else if (!persistedDate) {
    localStorage.setItem("persistedDate", formattedDate);
  }

  // store.dispatch({ type: "randomTrivia/resetState" });

  return next(action);
};

const persistedRandomTriviaReducer = persistReducer(
  randomTriviaPersistConfig,
  randomTriviaReducer
);
const persistedDailyTriviaReducer = persistReducer(
  dailyTriviaPersistConfig,
  dailyTriviaReducer
);
const persistedGlobalStateReducer = persistReducer(
  globalStatePersistConfig,
  globalStateReducer
);

const store = configureStore({
  reducer: {
    randomTrivia: persistedRandomTriviaReducer,
    // randomTrivia: randomTriviaReducer,
    dailyTrivia: persistedDailyTriviaReducer,
    globalState: persistedGlobalStateReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(resetStateOnNewDay),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
