import { useEffect, useState } from "react";
import { ChibbityQuestionV2 } from "../../../data/DataModels";
import styles from "./PlayCard.module.css";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { PlaySource } from "../../../data/Enums";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux_v2/store";
import { Button } from "@mui/material";
// import { BooleanLiteral } from "typescript";
// import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";

interface UserGuessInputProps {
  source: PlaySource;
  question: ChibbityQuestionV2;
  cannot_guess_anymore: boolean;
  showUserWasCorrect: boolean | null;
  inputAns: string;
  setInputAns: React.Dispatch<React.SetStateAction<string>>;
  inputRef: React.RefObject<HTMLInputElement>;
  answerEntered: boolean;
  incorrectInputAnimationActive: boolean;
  correctInputAnimationActive: boolean;
  closeInputAnimationActive: boolean;
  checkAnswer: (ans: string) => void;
  inputPlaceholder: string;
  setInputPlaceholder: React.Dispatch<React.SetStateAction<string>>;
  gaveUp: boolean;
  setRandomGuessCount: React.Dispatch<React.SetStateAction<number>>;
}
export default function UserGuessInput(props: UserGuessInputProps) {
  const [shuffledAnswers, setShuffledAnswers] = useState<string[]>([]);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleKeyPress = (e: any, ans: string) => {
    if (e.key === "Enter") {
      props.checkAnswer(ans);
      props.setRandomGuessCount((prev) => prev + 1);
    }
  };

  const currQuestionAnsweredObj = useSelector(
    (state: RootState) =>
      state.randomTrivia.answeredQuestions[props.question.question_id]
        ?.answered ?? null
  );

  useEffect(() => {
    const inputs = document.querySelectorAll("input, textarea");

    const handleFocus = () => {
      setScrollPosition(window.scrollY);
    };

    const handleBlur = () => {
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth", // Add smooth scrolling behavior
      });
    };

    inputs.forEach((input) => {
      input.addEventListener("focus", handleFocus);
      input.addEventListener("blur", handleBlur);
    });

    return () => {
      inputs.forEach((input) => {
        input.removeEventListener("focus", handleFocus);
        input.removeEventListener("blur", handleBlur);
      });
    };
  }, [scrollPosition]);

  useEffect(() => {
    if (props.question && props.question.accepted_correct_answers) {
      let loc_shuffled_answers: string[] = [];
      if (props.question.question_type === "multiple_choice") {
        loc_shuffled_answers = [
          ...(props.question.accepted_correct_answers ?? []),
          ...(props.question.incorrect_multiple_choices ?? []),
        ];
        for (let i = 0; i < loc_shuffled_answers.length; i++) {
          const rand_idx = Math.floor(Math.random() * i);
          const temp = loc_shuffled_answers[rand_idx];
          loc_shuffled_answers[rand_idx] = loc_shuffled_answers[i];
          loc_shuffled_answers[i] = temp;
        }
      }
      setShuffledAnswers(loc_shuffled_answers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.question]);

  // TODO add close guess animation to random play
  // TODO add local host support for connecting to trivia DB

  return currQuestionAnsweredObj !== null ? (
    <></>
  ) : (
    <div className={styles.question_section}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {" "}
        {props.cannot_guess_anymore ||
        props.showUserWasCorrect ||
        props.gaveUp ? (
          <></>
        ) : props.question.question_type === "short_answer" ? (
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              // width: "150%",
              // left: "-25%",
              // margin: "0 auto",
            }}
          >
            {/* <Button
                      onClick={handleHintButtonClick}
                      color={"primary"}
                      variant={"contained"}
                      disabled={
                        singleQuestionUserAnswers?.hintAcitvated ?? false
                      }
                    >
                      Show hint
                    </Button> */}
            <div onKeyDown={(e) => handleKeyPress(e, props.inputAns)}>
              <TextField
                inputRef={props.inputRef}
                // color={"primary"}
                variant="filled"
                className={
                  props.source === PlaySource.DAILY && props.answerEntered
                    ? styles.enter_pulse
                    : props.source === PlaySource.RANDOM &&
                        props.incorrectInputAnimationActive
                      ? styles.incorrect_animation
                      : props.source === PlaySource.RANDOM &&
                          props.correctInputAnimationActive
                        ? styles.correct_animation
                        : props.source === PlaySource.RANDOM &&
                            props.closeInputAnimationActive
                          ? styles.close_animation
                          : styles.normal_no_animation
                }
                value={props.inputPlaceholder}
                onChange={(e) => {
                  props.setInputAns(e.target.value);
                  props.setInputPlaceholder(e.target.value);
                }}
                placeholder={
                  props.cannot_guess_anymore
                    ? "no more guesses :("
                    : "answer me!"
                }
                disabled={props.cannot_guess_anymore}
              />
              {/* <Button
                        variant="contained"
                        color="primary"
                        style={{ height: "100%" }}
                      >
                        <DoubleArrowIcon color="inherit" />
                      </Button> */}
            </div>
            {/* <Button
                      onClick={handleGaveUpButtonClick}
                      color={"primary"}
                      disabled={isGiveUpDisabled}
                    >
                      Give up!
                    </Button> */}
          </div>
        ) : props.question.question_type === "true_false" ? (
          <>
            <Button
              onClick={() => props.checkAnswer("true")}
              color={"primary"}
              variant={"contained"}
              // style={{ maxWidth: "10vw" }}
            >
              True
            </Button>
            <Button
              onClick={() => props.checkAnswer("false")}
              color={"primary"}
              variant={"contained"}
              // style={{ maxWidth: "10vw" }}
            >
              {" "}
              False
            </Button>
          </>
        ) : props.question.question_type === "multiple_choice" ? (
          <div className={styles.multiple_choice_buttons_container}>
            {shuffledAnswers.map((question_answer, idx) => {
              return (
                <div key={props.question.question_id + idx}>
                  <Button
                    onClick={() => props.checkAnswer(question_answer)}
                    color={"primary"}
                    variant={"contained"}
                  >
                    {question_answer}
                  </Button>
                </div>
              );
            })}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
