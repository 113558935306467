import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  current,
} from "@reduxjs/toolkit";
import axios from "axios";
import {
  ChibbityQuestionV2,
  ChibbityQuestionQueryParams,
  ChibbityPaginationResponseObject,
  ChibbityPaginationData,
} from "../data/DataModels";

export const fetchRandomTriviaData = createAsyncThunk(
  "randomTrivia/fetchRandomTriviaData",
  async ({
    fetch_cnt,
    queryParams,
  }: {
    fetch_cnt?: number;
    queryParams?: ChibbityQuestionQueryParams;
  }) => {
    console.log("fetch count");
    console.log(fetch_cnt);
    console.log("query params just before!!!!!!");
    console.log(queryParams);
    const response = await axios.post(
      "https://api.triviaowls.xyz/v2/randomForRating",
      {
        ...queryParams,
        limit: fetch_cnt ?? 5,
      },
      { withCredentials: true }
    );
    return response.data as ChibbityPaginationResponseObject;
  }
);

export interface userRandomAnswerHistory {
  // questionId: string;
  // questionIdx: number;
  // answerGuesses: Answer[];
  answered: boolean;
  // questionComplete: boolean;
  // progressBarShown: boolean;
  // answeredCorrectly: boolean | null;
  // hintAcitvated: boolean;
  closestAnswer: string | null;
}

interface RandomTriviaState {
  randomTriviaQuestions: ChibbityQuestionV2[];
  randomTriviaIdx: number;
  queryParams: ChibbityQuestionQueryParams;
  answeredQuestions: { [key: string]: userRandomAnswerHistory };
  correctCount: number;
  loading: boolean;
  error: string | null;
  questionsVisible: number;
  paginationData: ChibbityPaginationData;
}

const initialState: RandomTriviaState = {
  randomTriviaQuestions: [],
  randomTriviaIdx: 0,
  queryParams: {},
  answeredQuestions: {},
  correctCount: 0,
  loading: false,
  error: null,
  questionsVisible: 1,
  paginationData: {
    last_cursor: "df",
    page_idx: 0,
    page_size: 0,
    has_more: true,
  },
};

type QuestionCompletePayload = {
  question_id: string;
  correct: boolean | null;
  closestAnswer: string | null;
};

// type ClosestAnswerPayload = {
//   question_id: string;
//   closestAnswer: string | null;
// };

const randomTriviaSlice = createSlice({
  name: "randomTrivia",
  initialState,
  reducers: {
    setTriviaData(state, action: PayloadAction<ChibbityQuestionV2[]>) {
      state.randomTriviaQuestions = action.payload;
    },
    setQueryParams(state, action: PayloadAction<ChibbityQuestionQueryParams>) {
      console.log("query params before");
      console.log(current(state.queryParams)); // Unwrapped state value
      console.log("action payload");
      console.log(action.payload);
      state.queryParams = action.payload;
    },
    setPaginationData(
      state,
      action: PayloadAction<ChibbityPaginationResponseObject>
    ) {
      state.paginationData = {
        last_cursor: action.payload.last_cursor,
        page_idx: action.payload.page_idx,
        page_size: action.payload.page_size,
        has_more: action.payload.has_more,
      };
      return state;
      // state.paginationData = { ...action.payload }; // Force a new reference
    },
    // setPaginationData2(state, action: PayloadAction<ChibbityPaginationData>) {
    //   state.paginationData = action.payload;
    //   // state.paginationData = { ...action.payload }; // Force a new reference
    // },
    // setClosestAnswer(state, action: PayloadAction<ClosestAnswerPayload>) {
    //   new data =-

    //   state.queryParams = action.payload;
    // },
    addQuestionAnsweredID(
      state,
      action: PayloadAction<QuestionCompletePayload>
    ) {
      const existing_entry =
        state.answeredQuestions[action.payload.question_id];

      state.answeredQuestions = {
        ...state.answeredQuestions,
        [action.payload.question_id]: {
          answered: existing_entry?.answered ?? action.payload.correct ?? null,
          closestAnswer:
            existing_entry?.closestAnswer ?? action.payload.closestAnswer,
        },
      };
    },
    addTriviaQuestions(state, action: PayloadAction<ChibbityQuestionV2[]>) {
      state.randomTriviaQuestions = [
        ...state.randomTriviaQuestions,
        ...action.payload,
      ];
    },
    incrementRandomQuestionsVisible(state) {
      console.log(current(state))
      state.questionsVisible = state.questionsVisible + 1;
    },
    resetTriviaData(state) {
      state.randomTriviaQuestions = [];
    },
    setRandomQuestionIdx(state, action: PayloadAction<number>) {
      state.randomTriviaIdx = action.payload;
    },
    incrementRandomQuestionIdx(state) {
      state.randomTriviaIdx = Math.min(
        state.randomTriviaIdx + 1,
        state.randomTriviaQuestions.length - 1
      );
    },
    decrementRandomQuestionIdx(state) {
      state.randomTriviaIdx = Math.max(state.randomTriviaIdx - 1, 0);
    },
    incrementCorrectCount(state) {
      console.log("incrementing is happening");
      state.correctCount = state.correctCount + 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRandomTriviaData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRandomTriviaData.fulfilled, (state, action) => {
        state.loading = false;
      })
      // .addCase(
      //   fetchRandomTriviaData.fulfilled,
      //   (state, action: PayloadAction<ChibbityPaginationResponseObject>) => {
      //     console.log("state fulfilled!!!!!!!!!!!!!!")
      //     state.loading = false;
      //     state.paginationData = {
      //       last_cursor: action.payload.last_cursor,
      //       page_idx: action.payload.page_idx,
      //       page_size: action.payload.page_size,
      //       has_more: action.payload.has_more,
      //     };
      //   }
      // )
      .addCase(fetchRandomTriviaData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "Something went wrong";
      });
  },
});

export const {
  setTriviaData,
  setQueryParams,
  addTriviaQuestions,
  // setPaginationData2,
  resetTriviaData,
  setRandomQuestionIdx,
  incrementRandomQuestionIdx,
  decrementRandomQuestionIdx,
  incrementCorrectCount,
  incrementRandomQuestionsVisible,
  addQuestionAnsweredID,
  setPaginationData,
} = randomTriviaSlice.actions;
export default randomTriviaSlice.reducer;
