import * as React from "react";
// import SvgIcon from "@mui/material/SvgIcon";
// import styles from "./SiteMarkIcon.module.css";
import { useNavigate } from "react-router-dom";

export default function SitemarkIcon() {
  const navigate = useNavigate();

  const handleNavigate = (loc: string) => {
    navigate(loc);
  };

  return (
    <div onClick={() => handleNavigate("/")}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <img
            style={{ maxWidth: "50px" }}
            src="/media/trivial_owl_blue.png"
            alt="logo"
            // className={styles.original}
          />
          {/* <img
            style={{ maxWidth: "50px" }}
            src="/media/trivial_owl_blue.png"
            alt="logo"
            className={styles.backlight}
          /> */}
          {/* Trivia Owl API */}
        </div>
      </div>
    </div>
  );
}
