import { ChibbityQuestion } from "../../data/DataModels";
import styles from "./PlayCard.module.css";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { PlaySource } from "../../data/Enums";
import FlexBox from "../DEPRECATED_legacy_components/utils/FlexBox";
import { Box, Paper, Stack, styled } from "@mui/material";
import {
  Answer_DEPRECATED,
  UserAnswerHistory_DEPRECATED,
} from "../DEPRECATED_redux/dailyTriviaSlice";
// import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  flexGrow: 1,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
  wordWrap: "break-word",
}));

interface AnswerGuessStackProps {
  source: PlaySource;
  question: ChibbityQuestion;
  singleQuestionUserAnswers: UserAnswerHistory_DEPRECATED | undefined;
  incorrectInputAnimationActive: boolean;
  correctInputAnimationActive: boolean;
  closeInputAnimationActive: boolean;
}

export default function AnswerGuessStack(props: AnswerGuessStackProps) {
  return (
    <Box
      sx={{
        padding: "auto",
        typography: "body1",
        justifyContent: "center",
        width: "auto",
        minWidth: "20px",
        maxWidth: "300px",
        margin: "0 auto",
      }}
    >
      <Stack spacing={2} justifyContent={"center"}>
        {props.singleQuestionUserAnswers?.answerGuesses.map(
          (answer_item: Answer_DEPRECATED, i: number) => {
            return (
              <Item
                key={props.question.question_id + i}
                style={{
                  backgroundColor: answer_item.isCorrect
                    ? "primary"
                    : "secondary",
                }}
                className={
                  i === 0 && props.incorrectInputAnimationActive
                    ? styles.incorrect_animation
                    : i === 0 && props.correctInputAnimationActive
                      ? styles.correct_animation
                      : i === 0 && props.closeInputAnimationActive
                        ? styles.close_animation
                        : styles.normal_no_animation
                }
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    flexDirection: "row",
                  }}
                >
                  <FlexBox dir="col">
                    <div style={{ width: "10%" }}>
                      {answer_item.isCorrect === 1 ? (
                        <DoneIcon color="success" />
                      ) : answer_item.isCorrect === -1 ? (
                        <CloseIcon color="error" />
                      ) : answer_item.isCorrect === 0 ? (
                        <CloseIcon color="warning" />
                      ) : answer_item.isCorrect === 2 ? (
                        <QuestionMarkIcon color="warning" />
                      ) : (
                        <></>
                      )}
                    </div>
                  </FlexBox>

                  <div
                    style={{
                      width: "80%",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    {answer_item.answerText}
                  </div>
                  <div
                    style={{
                      width: "10%",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    {(props.singleQuestionUserAnswers?.answerGuesses.length ??
                      4) - i}
                  </div>
                </div>
              </Item>
            );
          }
        )}
      </Stack>
    </Box>
  );
}
