import { ChibbityQuestionV2 } from "../../../data/DataModels";
import styles from "./HintsStack.module.css";
import { PlaySource } from "../../../data/Enums";
import { Box, Paper, Stack, styled, Typography } from "@mui/material";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import { useEffect, useState } from "react";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  flexGrow: 1,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
  wordWrap: "break-word",
}));

interface HintsStackProps {
  source: PlaySource;
  question: ChibbityQuestionV2;
  hints: string[];
  shouldAnimate: boolean;
  incorrectInputAnimationActive: boolean;
  correctInputAnimationActive: boolean;
  closeInputAnimationActive: boolean;
}

export default function HintsStack(props: HintsStackProps) {
  const [animationActive, setAnimationActive] = useState<boolean>(false);

  useEffect(() => {
    if (
      (props.incorrectInputAnimationActive ||
        props.correctInputAnimationActive ||
        props.closeInputAnimationActive) &&
      props.shouldAnimate &&
      props.source === PlaySource.DAILY
    ) {
      setAnimationActive(true);
      setTimeout(() => {
        setAnimationActive(false);
      }, 1000);
    }
  }, [
    props.closeInputAnimationActive,
    props.correctInputAnimationActive,
    props.incorrectInputAnimationActive,
    props.shouldAnimate,
    props.source,
  ]);

  if (props.hints.length === 0) {
    return <></>;
  }

  return (
    <Box
      sx={{
        padding: "auto",
        typography: "body1",
        justifyContent: "center",
        width: "auto",
        minWidth: "20px",
        maxWidth: "300px",
        margin: "0 auto",
      }}
    >
      <Typography
        key="hint3"
        variant="body1"
        color="textSecondary"
        width={"15%"}
      >
        Hints
      </Typography>
      <Stack spacing={1} justifyContent={"center"}>
        {props.hints.map((hint, i) => {
          return (
            <Item
              key={props.question.question_id + i}
              className={
                i === 0 && animationActive
                  ? styles.correct_animation
                  : styles.correct_animation_visible
              }
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ width: "10%" }}>
                    {props.hints.length - i === 1 ? (
                      <LooksOneIcon color="success" />
                    ) : props.hints.length - i === 2 ? (
                      <LooksTwoIcon color="warning" />
                    ) : props.hints.length - i === 3 ? (
                      <Looks3Icon color="error" />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{
                    width: "80%",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {hint}
                </Typography>
              </div>
            </Item>
          );
        })}
      </Stack>
    </Box>
  );
}
