import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  ChibbityQuestion,
  ChibbityQuestionQueryParams,
} from "../../data/DataModels";

export const fetchRandomTriviaData = createAsyncThunk(
  "randomTriviaData_DEPRECATED/fetchRandomTriviaData",
  async ({
    fetch_cnt,
    queryParams,
  }: {
    fetch_cnt?: number;
    queryParams?: ChibbityQuestionQueryParams;
  }) => {
    const response = await axios.post(
      "https://api.triviaowls.xyz/v1/randomForRating",
      {
        ...queryParams,
        limit: fetch_cnt ?? 5,
      },
      { withCredentials: true }
    );
    return response.data as ChibbityQuestion[];
  }
);

export interface userRandomAnswerHistory_DEPRECATED {
  // questionId: string;
  // questionIdx: number;
  // answerGuesses: Answer[];
  answered: boolean;
  // questionComplete: boolean;
  // progressBarShown: boolean;
  // answeredCorrectly: boolean | null;
  // hintAcitvated: boolean;
  closestAnswer: string | null;
}

interface RandomTriviaState_DEPRECATED {
  randomTriviaQuestions: ChibbityQuestion[];
  randomTriviaIdx: number;
  queryParams: ChibbityQuestionQueryParams;
  answeredQuestions: { [key: string]: userRandomAnswerHistory_DEPRECATED };
  correctCount: number;
  loading: boolean;
  error: string | null;
  questionsVisible: number;
}

const initialState: RandomTriviaState_DEPRECATED = {
  randomTriviaQuestions: [],
  randomTriviaIdx: 0,
  queryParams: {},
  answeredQuestions: {},
  correctCount: 0,
  loading: false,
  error: null,
  questionsVisible: 1,
};

type QuestionCompletePayload_DEPRECATED = {
  question_id: string;
  correct: boolean | null;
  closestAnswer: string | null;
};

// type ClosestAnswerPayload = {
//   question_id: string;
//   closestAnswer: string | null;
// };

const randomTriviaSlice = createSlice({
  name: "randomTriviaData_DEPRECATED",
  initialState,
  reducers: {
    setTriviaData(state, action: PayloadAction<ChibbityQuestion[]>) {
      state.randomTriviaQuestions = action.payload;
    },
    setQueryParams(state, action: PayloadAction<ChibbityQuestionQueryParams>) {
      state.queryParams = action.payload;
    },
    // setClosestAnswer(state, action: PayloadAction<ClosestAnswerPayload>) {
    //   new data =- 

    //   state.queryParams = action.payload;
    // },
    addQuestionAnsweredID(
      state,
      action: PayloadAction<QuestionCompletePayload_DEPRECATED>
    ) {
      const existing_entry = state.answeredQuestions[action.payload.question_id]

      state.answeredQuestions = {
        ...state.answeredQuestions,
        [action.payload.question_id]: {
          answered: existing_entry?.answered ?? action.payload.correct ?? null,
          closestAnswer: existing_entry?.closestAnswer ?? action.payload.closestAnswer
        },
      };
    },
    addTriviaQuestions(state, action: PayloadAction<ChibbityQuestion[]>) {
      state.randomTriviaQuestions = [
        ...state.randomTriviaQuestions,
        ...action.payload,
      ];
    },
    incrementRandomQuestionsVisible(state) {
      state.questionsVisible = state.questionsVisible + 1;
    },
    resetTriviaData(state) {
      state.randomTriviaQuestions = [];
    },
    setRandomQuestionIdx(state, action: PayloadAction<number>) {
      state.randomTriviaIdx = action.payload;
    },
    incrementRandomQuestionIdx(state) {
      state.randomTriviaIdx = Math.min(
        state.randomTriviaIdx + 1,
        state.randomTriviaQuestions.length - 1
      );
    },
    decrementRandomQuestionIdx(state) {
      state.randomTriviaIdx = Math.max(state.randomTriviaIdx - 1, 0);
    },
    incrementCorrectCount(state) {
      state.correctCount = state.correctCount + 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRandomTriviaData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRandomTriviaData.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(fetchRandomTriviaData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "Something went wrong";
      });
  },
});

export const {
  setTriviaData,
  setQueryParams,
  addTriviaQuestions,
  resetTriviaData,
  setRandomQuestionIdx,
  incrementRandomQuestionIdx,
  decrementRandomQuestionIdx,
  incrementCorrectCount,
  incrementRandomQuestionsVisible,
  addQuestionAnsweredID,
} = randomTriviaSlice.actions;
export default randomTriviaSlice.reducer;
