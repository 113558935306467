import { useEffect, useMemo, useRef, useState } from "react";
import {
  ChibbityCheckAnswerResponse,
  ChibbityQuestionV2,
} from "../../../data/DataModels";
import styles from "./PlayCard.module.css";
import Button from "@mui/material/Button";
import { PlaySource, SegmentOptions } from "../../../data/Enums";
import { Box, Container, LinearProgress, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addAnswerGuess,
  incrementDailyQuestionIdx,
  selectUserAnswersForQuestion,
  setDailyQuestionGiveUp,
  setprogressBarShown,
} from "../../../redux_v2/dailyTriviaSlice";
import { RootState } from "../../../redux_v2/store";
import axios from "axios";
import { ALLOWED_GUESSES_CNT } from "../../../data/AppConst";
import {
  addQuestionAnsweredID,
  incrementCorrectCount,
} from "../../../redux_v2/randomTriviaSlice";
import { PAGE_TURN_TIMER_MS } from "../../configs/ux_configs";
import UserGuessInput from "./PlayUserInput";
import AnswerGuessStack from "./AnswerGuessStack";
import AnswerCard from "./AnswerCard";
import HintsStack from "./HintsStack";

export default function PlayCard({
  source,
  question,
  votedQs,
  setVotedQs,
}: {
  source: PlaySource;
  question: ChibbityQuestionV2;
  votedQs: Set<string>;
  setVotedQs: React.Dispatch<React.SetStateAction<Set<string>>>;
}) {
  const [inputAns, setInputAns] = useState<string>("");
  const [nextQuestionProgress, setNextQuestionProgress] = useState<number>(0);
  const [inputPlaceholder, setInputPlaceholder] = useState<string>("");
  const [currSegment, setCurrSegment] = useState<string>(SegmentOptions.REVIEW);
  const [incorrectInputAnimationActive, setIncorrectInputAnimationActive] =
    useState(false);
  const [correctInputAnimationActive, setCorrectInputAnimationActive] =
    useState(false);
  const [closeInputAnimationActive, setCloseInputAnimationActive] =
    useState(false);
  const [showUserWasCorrect, setShowUserWasCorrect] = useState<boolean | null>(
    null
  );
  const [answerEntered, setAnswerEntered] = useState<boolean>(false);
  const [gaveUp, setGaveUp] = useState<boolean>(false);
  const [randomGuessCount, setRandomGuessCount] = useState<number>(0);
  const [isGiveUpDisabled, setIsGaveUpDisabled] = useState<boolean>(false);
  const [userAnsweredCorrectly, setUserAnsweredCorrectly] = useState<
    boolean | null
  >(null);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [question, inputRef, inputAns]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const dispatch = useDispatch();
  const { dailyQuestionIdx } = useSelector(
    (state: RootState) => state.dailyTrivia
  );
  const prevQuestionIdx = useRef(dailyQuestionIdx);

  const singleQuestionUserAnswers = useSelector((state: RootState) =>
    selectUserAnswersForQuestion(state, question?.question_id ?? "")
  );

  const [showHint, setShowHint] = useState<boolean>(
    singleQuestionUserAnswers?.hintAcitvated ?? false
  );

  const handleGaveUpButtonClick = () => {
    // setTimeout(() => {
    setIsGaveUpDisabled(true);
    // }, 400);
    setShowUserWasCorrect(false);
    dispatch(setDailyQuestionGiveUp(question.question_id));
    setGaveUp(true);
    if (source === PlaySource.DAILY) {
      handleDailyPageIncrement();
    }
  };

  const checkAnswer = (ans: string) => {
    setAnswerEntered(true);
    setTimeout(() => {
      setAnswerEntered(false);
    }, 500);
    const response = axios.post(
      "https://api.triviaowls.xyz/v1/checkAnswer",
      {
        question_id: question.question_id,
        user_answer: ans,
        accepted_correct_answers: question.accepted_correct_answers,
      },
      { withCredentials: true }
    );
    setInputPlaceholder("");

    response.then((response) => {
      const check_ans_data = response.data as ChibbityCheckAnswerResponse;
      if (check_ans_data.user_answer_correct === 1) {
        handleCorrectAns(ans, check_ans_data.closest_answer);
      } else if (check_ans_data.user_answer_correct === -1) {
        handleIncorrectAns(ans);
      } else if (check_ans_data.user_answer_correct === 0) {
        handleCloseAns(ans);
      }
    });
  };

  const handleDailyPageIncrement = () => {
    let timeout_ref: string | number | NodeJS.Timeout | undefined;
    timeout_ref = setTimeout(() => {
      if (prevQuestionIdx.current === dailyQuestionIdx) {
        dispatch(incrementDailyQuestionIdx());
      }
    }, PAGE_TURN_TIMER_MS);

    return () => {
      dispatch(setprogressBarShown(dailyQuestionIdx));

      if (timeout_ref && prevQuestionIdx.current !== dailyQuestionIdx) {
        clearTimeout(timeout_ref);
      }
      prevQuestionIdx.current = dailyQuestionIdx;
    };
  };

  const handleCorrectAns = (ans: string, closestRealAnswer: string) => {
    // setCorrectAnswer(true);
    setCorrectInputAnimationActive(true);
    setTimeout(() => {
      setCorrectInputAnimationActive(false);
    }, 500);
    setShowUserWasCorrect(true);
    dispatch(
      addAnswerGuess({
        questionId: question.question_id,
        questionIdx: dailyQuestionIdx,
        answer: ans,
        isCorrect: 1,
        hintShown: showHint,
        closestAnswer: closestRealAnswer,
      })
    );
    setUserAnsweredCorrectly(true);
    if (source === PlaySource.DAILY) {
      handleDailyPageIncrement();
    } else if (source === PlaySource.RANDOM) {
      dispatch(incrementCorrectCount());
      dispatch(
        addQuestionAnsweredID({
          question_id: question.question_id,
          correct: null,
          closestAnswer: closestRealAnswer,
        })
      );
      // handleRandomPageIncrement();
    }
  };

  const handleCloseAns = (ans: string) => {
    // setCorrectAnswer(true);
    setCloseInputAnimationActive(true);
    setTimeout(() => {
      setCloseInputAnimationActive(false);
    }, 500);
    dispatch(
      addAnswerGuess({
        questionId: question.question_id,
        questionIdx: dailyQuestionIdx,
        answer: ans,
        isCorrect: 0,
        hintShown: showHint,
        closestAnswer: null,
      })
    );
    if (
      singleQuestionUserAnswers?.answerGuesses.length ===
        ALLOWED_GUESSES_CNT - 1 &&
      source === PlaySource.DAILY
    ) {
      handleDailyPageIncrement();
    } else if (source === PlaySource.RANDOM) {
      setUserAnsweredCorrectly(false);
    }
  };

  const handleIncorrectAns = (ans: string) => {
    setIncorrectInputAnimationActive(true);
    setShowUserWasCorrect(false);
    setTimeout(() => {
      setIncorrectInputAnimationActive(false);
    }, 500);
    setInputPlaceholder("");

    dispatch(
      addAnswerGuess({
        questionId: question.question_id,
        questionIdx: dailyQuestionIdx,
        answer: ans,
        isCorrect: -1,
        hintShown: showHint,
        closestAnswer: null,
      })
    );
    if (
      singleQuestionUserAnswers?.answerGuesses.length ===
        ALLOWED_GUESSES_CNT - 1 &&
      source === PlaySource.DAILY
    ) {
      handleDailyPageIncrement();
    } else if (source === PlaySource.RANDOM) {
      setUserAnsweredCorrectly(false);
    }
  };

  useEffect(() => {
    if (
      currSegment === SegmentOptions.HINT ||
      currSegment === SegmentOptions.ANSWER
    ) {
      setCurrSegment("");
    }
    setInputAns("");
    setShowUserWasCorrect(false);
    // setIsHintDisabled(false);
    setShowHint(false);
    setInputPlaceholder("");
    setIsGaveUpDisabled(false);
    setUserAnsweredCorrectly(null);
    setGaveUp(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  const show_progress_bar =
    source === PlaySource.DAILY &&
    (singleQuestionUserAnswers?.answeredCorrectly !== null ||
      userAnsweredCorrectly !== null ||
      gaveUp) &&
    !singleQuestionUserAnswers?.progressBarShown;

  useEffect(() => {
    let timeout_ref: string | number | NodeJS.Timeout | undefined;

    if (singleQuestionUserAnswers?.progressBarShown === true) {
      setNextQuestionProgress(0);
      //whatever
    } else if (source !== PlaySource.DAILY) {
      prevQuestionIdx.current = dailyQuestionIdx;
      return;
    } else if (nextQuestionProgress !== 100 && !show_progress_bar) {
      setNextQuestionProgress(0);
    } else if (
      prevQuestionIdx.current !== dailyQuestionIdx &&
      prevQuestionIdx.current !== dailyQuestionIdx &&
      show_progress_bar
    ) {
      setNextQuestionProgress(0);
    } else if (
      show_progress_bar &&
      nextQuestionProgress !== 100 &&
      showUserWasCorrect !== null &&
      prevQuestionIdx.current === dailyQuestionIdx
    ) {
      timeout_ref = setTimeout(() => {
        setNextQuestionProgress(nextQuestionProgress + 5);
      }, PAGE_TURN_TIMER_MS / 22.5);
    }

    return () => {
      if (timeout_ref && prevQuestionIdx.current !== dailyQuestionIdx) {
        clearTimeout(timeout_ref);
        setNextQuestionProgress(0);
      }
      prevQuestionIdx.current = dailyQuestionIdx;
    };
  }, [
    nextQuestionProgress,
    userAnsweredCorrectly,
    gaveUp,
    singleQuestionUserAnswers,
    source,
    show_progress_bar,
    dailyQuestionIdx,
    showUserWasCorrect,
  ]);

  useEffect(() => {
    setRandomGuessCount(0);
  }, [question]);

  const hints_to_show = useMemo(() => {
    const guess_counter_source =
      source === PlaySource.RANDOM
        ? randomGuessCount
        : (singleQuestionUserAnswers?.answerGuesses.length ?? 0);

    const eligible_guesses =
      guess_counter_source - (show_progress_bar && !gaveUp ? 1 : 0);
    const hints_to_show = [];
    if (eligible_guesses >= 3) {
      hints_to_show.push(question.hint3);
    }
    if (eligible_guesses >= 2) {
      hints_to_show.push(question.hint2);
    }
    if (eligible_guesses >= 1) {
      hints_to_show.push(question.hint1);
    }

    return hints_to_show;
  }, [
    gaveUp,
    question.hint1,
    question.hint2,
    question.hint3,
    randomGuessCount,
    show_progress_bar,
    singleQuestionUserAnswers?.answerGuesses.length,
    source,
  ]);

  if (!question) {
    return <></>;
  }

  const cannot_guess_anymore =
    singleQuestionUserAnswers?.questionComplete ?? false;

  return (
    <Container
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <div className={styles.question_container}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div className={styles.question_answer_wrapper}>
            <Typography
              variant="h6"
              color="textPrimary"
              className={styles.question}
            >
              {question.question}
            </Typography>
            <Box
              sx={{
                position: "relative",
                // left: "15%",
                width: "100%",
                margin: "0px",
                padding: "10px",
              }}
            >
              {show_progress_bar &&
              !singleQuestionUserAnswers?.progressBarShown ? (
                <LinearProgress
                  variant="determinate"
                  value={nextQuestionProgress}
                />
              ) : (
                <></>
              )}
              {!show_progress_bar ||
              singleQuestionUserAnswers?.progressBarShown ? (
                <LinearProgress variant="determinate" value={100} />
              ) : (
                <></>
              )}
            </Box>
            <AnswerCard
              source={source}
              question={question}
              singleQuestionUserAnswers={singleQuestionUserAnswers}
              incorrectInputAnimationActive={incorrectInputAnimationActive}
              correctInputAnimationActive={correctInputAnimationActive}
              closeInputAnimationActive={closeInputAnimationActive}
              userAnsweredCorrectly={userAnsweredCorrectly}
              gaveUp={gaveUp}
            />
            <UserGuessInput
              source={source}
              question={question}
              cannot_guess_anymore={cannot_guess_anymore}
              showUserWasCorrect={showUserWasCorrect}
              inputAns={inputAns}
              setInputAns={setInputAns}
              inputRef={inputRef}
              answerEntered={answerEntered}
              incorrectInputAnimationActive={incorrectInputAnimationActive}
              correctInputAnimationActive={correctInputAnimationActive}
              closeInputAnimationActive={closeInputAnimationActive}
              checkAnswer={checkAnswer}
              inputPlaceholder={inputPlaceholder}
              setInputPlaceholder={setInputPlaceholder}
              gaveUp={gaveUp}
              setRandomGuessCount={setRandomGuessCount}
            />
            <HintsStack
              source={source}
              question={question}
              hints={hints_to_show}
              shouldAnimate={
                !showUserWasCorrect &&
                !gaveUp &&
                (singleQuestionUserAnswers?.answerGuesses.length ?? 0) !== 4 &&
                randomGuessCount <= 3
              }
              incorrectInputAnimationActive={incorrectInputAnimationActive}
              correctInputAnimationActive={correctInputAnimationActive}
              closeInputAnimationActive={closeInputAnimationActive}
            />
            <br />
            <AnswerGuessStack
              source={source}
              question={question}
              singleQuestionUserAnswers={singleQuestionUserAnswers}
              incorrectInputAnimationActive={incorrectInputAnimationActive}
              correctInputAnimationActive={correctInputAnimationActive}
              closeInputAnimationActive={closeInputAnimationActive}
            />
            {cannot_guess_anymore ? (
              <></>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "row",
                    // maxWidth: "50vw",
                    marginTop: 40,
                  }}
                ></div>

                <br />

                <Button
                  onClick={handleGaveUpButtonClick}
                  color={"primary"}
                  variant={"contained"}
                  disabled={isGiveUpDisabled}
                  // style={{ maxWidth: "10vw" }}
                >
                  Give up!
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}
