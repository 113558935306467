import { useEffect } from "react";
import styles from "./PlayCard.module.css";

import { PlaySource } from "../../../data/Enums";

import {
  Box,
  // CircularProgress,
  Container,
  LinearProgress,
  Pagination,
  Typography,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import PlayCard from "./PlayCard";
import { AppDispatch, RootState } from "../../../redux_v2/store";
import {
  fetchDailyTriviaData,
  setDailyQuestionIdx,
  setprogressBarShown,
  setQuizFinished,
  setTriviaData,
} from "../../../redux_v2/dailyTriviaSlice";
import { PAGE_TURN_TIMER_MS } from "../../configs/ux_configs";

// PAGINATE

export default function PlayCardDaily({
  votedQs,
  setVotedQs,
}: {
  votedQs: Set<string>;
  setVotedQs: React.Dispatch<React.SetStateAction<Set<string>>>;
}) {
  const dispatch = useDispatch<AppDispatch>();
  const dailyTriviaQuestions = useSelector(
    (state: RootState) => state.dailyTrivia.dailyTriviaQuestions
  );
  const loading = useSelector((state: RootState) => state.dailyTrivia.loading);
  const error = useSelector((state: RootState) => state.dailyTrivia.error);
  const { dailyQuestionIdx } = useSelector(
    (state: RootState) => state.dailyTrivia
  );
  const dailyTriviaAnswers = useSelector(
    (state: RootState) => state.dailyTrivia.userAnswers
  );

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(setprogressBarShown(value - 1));
    dispatch(setDailyQuestionIdx(value - 1));
  };

  const fetchAndHandleTrivia = async () => {
    if (dailyTriviaQuestions.length === 0) {
      const resultAction = await dispatch(fetchDailyTriviaData());
      if (fetchDailyTriviaData.fulfilled.match(resultAction)) {
        const newQuestions = resultAction.payload;
        dispatch(setTriviaData([...newQuestions]));
      }
    }
  };

  useEffect(() => {
    fetchAndHandleTrivia();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let questions_complete = 0;
    for (const item of dailyTriviaAnswers) {
      if (item.questionComplete) {
        questions_complete++;
      }
    }
    if (questions_complete === 5) {
      setTimeout(() => {
        dispatch(setQuizFinished());
      }, PAGE_TURN_TIMER_MS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyTriviaAnswers]);

  if (
    !dailyTriviaQuestions ||
    dailyTriviaQuestions.length === 0 ||
    loading ||
    error
  ) {
    if (loading && dailyTriviaQuestions.length > 0 && !error) {
      // do nothing
    } else {
      return (
        <Box
          sx={{
            position: "relative",
            left: "15%",
            width: "70%",
            paddingTop: "15%",
          }}
        >
          <LinearProgress />
        </Box>
      );
      // return <BarLoader color="#1871c1" height="10" width="400" />;
    }
  }

  if (!dailyTriviaQuestions) {
    return <></>;
  }

  return (
    <Container
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 3 },
      }}
    >
      <Typography variant="h4" color="textPrimary">
        Daily Five
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Pagination
          count={dailyTriviaQuestions.length}
          page={dailyQuestionIdx + 1}
          onChange={handlePageChange}
          color="primary"
        />
      </div>
      <div className={styles.question_container}>
        <Box
          sx={{
            width: "100%",
            padding: "auto",
            typography: "body1",
            justifyContent: "center",
            maxWidth: "100%",
          }}
        >
          <PlayCard
            source={PlaySource.DAILY}
            question={dailyTriviaQuestions[dailyQuestionIdx]}
            votedQs={votedQs}
            setVotedQs={setVotedQs}
          />
        </Box>
      </div>
    </Container>
  );
}
