import * as React from "react";
// import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { visuallyHidden } from "@mui/utils";
import axios from "axios";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { setNewsletterSignupCompleted } from "../../../redux_v2/globalStateSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux_v2/store";

interface NewsletterSignupProps {
  CTA_text: string;
  button_text: string;
}

export default function NewsletterSignup(props: NewsletterSignupProps) {
  const [emailInput, setEmailInput] = React.useState<string>("");
  const [emailSubmitted, setEmailSubmitted] = React.useState<boolean>(false);
  const [error, setError] = React.useState("");
  const [isValid, setIsValid] = React.useState<boolean | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const signedup = useSelector(
    (state: RootState) => state.globalState.newsletterSignupCompleted
  );

  const handleNavigate = (loc: string) => {
    navigate(loc);
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      handleEmailSubmit();
    }
  };

  const handleEmailSubmit = () => {
    if (validateEmail(emailInput)) {
      setIsValid(true);
      setError("");
      setEmailSubmitted(true);
      dispatch(setNewsletterSignupCompleted());
      axios.post(
        "https://api.triviaowls.xyz/v1/newsletterSignup",
        {
          email: emailInput,
        },
        { withCredentials: true }
      );
    } else {
      setIsValid(false);
      setError("Invalid email address");
    }
  };

  const handleEmailChange = (e: any) => {
    const value = e.target.value;
    setEmailInput(value);
  };

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const button_box = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        position: "relative",
        paddingTop: "10px",
        width: "300px",
        flexWrap: "wrap",
      }}
    >
      <Button
        color="primary"
        variant="outlined"
        size="large"
        style={{ margin: "5px" }}
        onClick={() => handleNavigate("/api")}
      >
        API Docs
      </Button>
      <Button
        color="primary"
        variant="outlined"
        size="large"
        style={{ margin: "5px" }}
        href="https://rapidapi.com/ayeagle/api/trivia-owls"
        target="_blank"
      >
        RapidAPI
      </Button>
      <Button
        color="primary"
        variant="contained"
        size="large"
        style={{ margin: "5px" }}
        onClick={() => handleNavigate("/play")}
      >
        Daily Five Quiz
      </Button>
    </Box>
  );

  return (
    <>
      {!signedup ? (
        <>
          <Typography
            sx={{
              textAlign: "center",
              color: "text.secondary",
              width: { sm: "100%", md: "80%" },
            }}
          >
            {props.CTA_text}
          </Typography>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={1}
            useFlexGap
            sx={{
              pt: 2,
              width: { xs: "100%", sm: "350px" },
              justifyContent: "center",
            }}
          >
            <InputLabel htmlFor="email-hero" sx={visuallyHidden}>
              Email
            </InputLabel>
            <div onKeyDown={(e) => handleKeyPress(e)}>
              <TextField
                id="email-hero"
                type="email"
                hiddenLabel
                size="small"
                variant="outlined"
                onSubmit={handleEmailSubmit}
                onChange={handleEmailChange}
                helperText={error}
                label="Email"
                aria-label="Enter your email address"
                placeholder="Your email address"
                error={isValid === false}
                fullWidth
                slotProps={{
                  htmlInput: {
                    autoComplete: "off",
                    "aria-label": "Enter your email address",
                  },
                }}
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{ minWidth: "fit-content", maxHeight: "45px" }}
              onClick={handleEmailSubmit}
            >
              {props.button_text}
            </Button>
          </Stack>
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ textAlign: "center" }}
          >
            By clicking &quot;Start now&quot; you agree to our&nbsp;
            <Link href="#" color="primary">
              Terms & Conditions
            </Link>
            .
          </Typography>
        </>
      ) : emailSubmitted ? (
        <>
          {" "}
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ textAlign: "center" }}
          >
            Thanks for signing up! Check out our API docs or play the Daily
            Five.
          </Typography>
          {button_box}
        </>
      ) : (
        <>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ textAlign: "center" }}
          >
            Trivia Owl is the world's leader for high quality, performant, and
            developer friendly trivia tools.
          </Typography>
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ textAlign: "center" }}
          >
            Check out our API docs or play the Daily Five.
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              position: "relative",
              paddingTop: "10px",
              width: "300px",
              flexWrap: "wrap",
            }}
          >
            <Button
              color="primary"
              variant="outlined"
              size="large"
              style={{ margin: "5px" }}
              onClick={() => handleNavigate("/api")}
            >
              API Docs
            </Button>
            <Button
              color="primary"
              variant="outlined"
              size="large"
              style={{ margin: "5px" }}
              href="https://rapidapi.com/ayeagle/api/trivia-owls"
              target="_blank"
            >
              RapidAPI
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="large"
              style={{ margin: "5px" }}
              onClick={() => handleNavigate("/play")}
            >
              Daily Five Quiz
            </Button>
          </Box>
        </>
      )}
    </>
  );
}
