import { useEffect, useRef, useState } from "react";
import {
  ChibbityCheckAnswerResponse,
  ChibbityQuestion,
} from "../../data/DataModels";
import styles from "./PlayCard.module.css";
import Button from "@mui/material/Button";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { PlaySource, SegmentOptions } from "../../data/Enums";
import FlexBox from "../DEPRECATED_legacy_components/utils/FlexBox";
// import StandardButton from "../../../legacy_components/interaction_components/StandardButton";
// import TextField from "@mui/material/TextField";
// import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import {
  Box,
  // Card,
  // CardActions,
  // CardContent,
  Container,
  LinearProgress,
  // Paper,
  // Stack,
  // styled,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addAnswerGuess,
  // Answer,
  incrementDailyQuestionIdx,
  selectUserAnswersForQuestion,
  setDailyQuestionGiveUp,
  setHintActivated,
  setprogressBarShown,
} from "../DEPRECATED_redux/dailyTriviaSlice";
import { RootState } from "../DEPRECATED_redux/store";
// import DoneIcon from "@mui/icons-material/Done";
// import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { ALLOWED_GUESSES_CNT } from "../../data/AppConst";
import {
  addQuestionAnsweredID,
  incrementCorrectCount,
} from "../DEPRECATED_redux/randomTriviaSlice";
import { PAGE_TURN_TIMER_MS } from "../../v2/configs/ux_configs";
// import PlayUserInput from "./PlayUserInput";
// import PlayAnswerCard from "./AnswerGuessStack";
// import PlayAnswerGuessStack from "./AnswerGuessStack";
import UserGuessInput from "./PlayUserInput";
import AnswerGuessStack from "./AnswerGuessStack";
import AnswerCard from "./AnswerCard";

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: "#fff",
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
//   flexGrow: 1,
//   ...theme.applyStyles("dark", {
//     backgroundColor: "#1A2027",
//   }),
//   wordWrap: "break-word",
// }));

export default function PlayCard({
  source,
  question,
  votedQs,
  setVotedQs,
}: {
  source: PlaySource;
  question: ChibbityQuestion;
  votedQs: Set<string>;
  setVotedQs: React.Dispatch<React.SetStateAction<Set<string>>>;
}) {
  const [inputAns, setInputAns] = useState<string>("");
  const [nextQuestionProgress, setNextQuestionProgress] = useState<number>(0);
  // const [shuffledAnswers, setShuffledAnswers] = useState<string[]>([]);
  const [inputPlaceholder, setInputPlaceholder] = useState<string>("");
  const [currSegment, setCurrSegment] = useState<string>(SegmentOptions.REVIEW);
  const [incorrectInputAnimationActive, setIncorrectInputAnimationActive] =
    useState(false);
  const [correctInputAnimationActive, setCorrectInputAnimationActive] =
    useState(false);
  const [closeInputAnimationActive, setCloseInputAnimationActive] =
    useState(false);
  const [showUserWasCorrect, setShowUserWasCorrect] = useState<boolean | null>(
    null
  );
  // const [closestAnswer, setClosestAnswer] = useState<string>("");
  const [answerEntered, setAnswerEntered] = useState<boolean>(false);
  const [gaveUp, setGaveUp] = useState<boolean>(false);
  const [isGiveUpDisabled, setIsGaveUpDisabled] = useState<boolean>(false);
  const [userAnsweredCorrectly, setUserAnsweredCorrectly] = useState<
    boolean | null
  >(null);
  // const [isHintDisabled, setIsHintDisabled] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  // TODO ADD CLOSE UX FOR FREE PLAY

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // Set focus on the input element
    }
  }, [question, inputRef, inputAns]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // Set focus on the input element
    }
  }, []);

  const dispatch = useDispatch();
  const { dailyQuestionIdx } = useSelector(
    (state: RootState) => state.dailyTrivia
  );
  const prevQuestionIdx = useRef(dailyQuestionIdx);

  const singleQuestionUserAnswers = useSelector((state: RootState) =>
    selectUserAnswersForQuestion(state, question?.question_id ?? "")
  );

  const [showHint, setShowHint] = useState<boolean>(
    singleQuestionUserAnswers?.hintAcitvated ?? false
  );

  const handleHintButtonClick = () => {
    // setTimeout(() => {
    //   setIsHintDisabled(true);
    //   // dispatch(setHintActivated(question.question_id));
    // }, 200);

    dispatch(setHintActivated(question.question_id));

    dispatch(
      addAnswerGuess({
        questionId: question.question_id,
        questionIdx: dailyQuestionIdx,
        answer: "Revealed Hint",
        isCorrect: 2,
        hintShown: true,
        closestAnswer: null,
      })
    );

    setShowHint(!showHint);
  };

  const handleGaveUpButtonClick = () => {
    // setTimeout(() => {
    setIsGaveUpDisabled(true);
    // }, 400);
    setShowUserWasCorrect(false);
    dispatch(setDailyQuestionGiveUp(question.question_id));
    setGaveUp(true);
    if (source === PlaySource.DAILY) {
      handleDailyPageIncrement();
    }
  };

  const checkAnswer = (ans: string) => {
    setAnswerEntered(true);
    setTimeout(() => {
      setAnswerEntered(false);
    }, 500);
    const response = axios.post(
      "https://api.triviaowls.xyz/v1/checkAnswer",
      {
        question_id: question.question_id,
        user_answer: ans,
        accepted_correct_answers: question.accepted_correct_answers,
      },
      { withCredentials: true }
    );
    setInputPlaceholder("");

    response.then((response) => {
      const check_ans_data = response.data as ChibbityCheckAnswerResponse;
      if (check_ans_data.user_answer_correct === 1) {
        handleCorrectAns(ans, check_ans_data.closest_answer);
      } else if (check_ans_data.user_answer_correct === -1) {
        handleIncorrectAns(ans);
      } else if (check_ans_data.user_answer_correct === 0) {
        handleCloseAns(ans);
      }
    });
  };

  const handleDailyPageIncrement = () => {
    let timeout_ref: string | number | NodeJS.Timeout | undefined;
    timeout_ref = setTimeout(() => {
      if (prevQuestionIdx.current === dailyQuestionIdx) {
        dispatch(incrementDailyQuestionIdx());
      }
    }, PAGE_TURN_TIMER_MS);

    return () => {
      dispatch(setprogressBarShown(dailyQuestionIdx));

      if (timeout_ref && prevQuestionIdx.current !== dailyQuestionIdx) {
        clearTimeout(timeout_ref);
      }
      prevQuestionIdx.current = dailyQuestionIdx;
    };
  };

  //
  //
  //
  //

  // const handleRandomPageIncrement = () => {
  //   setTimeout(() => {
  //     dispatch(incrementRandomQuestionIdx()); // Adjust by 1 as page is 1-based and index is 0-based
  //   }, 2000);
  // };

  const handleCorrectAns = (ans: string, closestRealAnswer: string) => {
    // setCorrectAnswer(true);
    setCorrectInputAnimationActive(true);
    setTimeout(() => {
      setCorrectInputAnimationActive(false);
    }, 500);
    setShowUserWasCorrect(true);
    dispatch(
      addAnswerGuess({
        questionId: question.question_id,
        questionIdx: dailyQuestionIdx,
        answer: ans,
        isCorrect: 1,
        hintShown: showHint,
        closestAnswer: closestRealAnswer,
      })
    );
    setUserAnsweredCorrectly(true);
    if (source === PlaySource.DAILY) {
      handleDailyPageIncrement();
    } else if (source === PlaySource.RANDOM) {
      dispatch(incrementCorrectCount());
      dispatch(
        addQuestionAnsweredID({
          question_id: question.question_id,
          correct: null,
          closestAnswer: closestRealAnswer,
        })
      );
      // handleRandomPageIncrement();
    }
  };

  const handleCloseAns = (ans: string) => {
    // setCorrectAnswer(true);
    setCloseInputAnimationActive(true);
    setTimeout(() => {
      setCloseInputAnimationActive(false);
    }, 500);
    dispatch(
      addAnswerGuess({
        questionId: question.question_id,
        questionIdx: dailyQuestionIdx,
        answer: ans,
        isCorrect: 0,
        hintShown: showHint,
        closestAnswer: null,
      })
    );
    if (
      singleQuestionUserAnswers?.answerGuesses.length ===
        ALLOWED_GUESSES_CNT - 1 &&
      source === PlaySource.DAILY
    ) {
      handleDailyPageIncrement();
    } else if (source === PlaySource.RANDOM) {
      setUserAnsweredCorrectly(false);
    }
  };

  const handleIncorrectAns = (ans: string) => {
    setIncorrectInputAnimationActive(true);
    setShowUserWasCorrect(false);
    setTimeout(() => {
      setIncorrectInputAnimationActive(false);
    }, 500);
    setInputPlaceholder("");

    dispatch(
      addAnswerGuess({
        questionId: question.question_id,
        questionIdx: dailyQuestionIdx,
        answer: ans,
        isCorrect: -1,
        hintShown: showHint,
        closestAnswer: null,
      })
    );
    if (
      singleQuestionUserAnswers?.answerGuesses.length ===
        ALLOWED_GUESSES_CNT - 1 &&
      source === PlaySource.DAILY
    ) {
      handleDailyPageIncrement();
    } else if (source === PlaySource.RANDOM) {
      setUserAnsweredCorrectly(false);
    }
  };

  useEffect(() => {
    if (
      currSegment === SegmentOptions.HINT ||
      currSegment === SegmentOptions.ANSWER
    ) {
      setCurrSegment("");
    }
    setInputAns("");
    setShowUserWasCorrect(false);
    // setIsHintDisabled(false);
    setShowHint(false);
    setInputPlaceholder("");
    setIsGaveUpDisabled(false);
    setUserAnsweredCorrectly(null);
    setGaveUp(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  const show_progress_bar =
    source === PlaySource.DAILY &&
    (singleQuestionUserAnswers?.answeredCorrectly !== null ||
      userAnsweredCorrectly !== null ||
      gaveUp) &&
    !singleQuestionUserAnswers?.progressBarShown;

  useEffect(() => {
    let timeout_ref: string | number | NodeJS.Timeout | undefined;
    // if (prevQuestionIdx.current !== dailyQuestionIdx) {
    //   setNextQuestionProgress(0);
    //   prevQuestionIdx.current = dailyQuestionIdx;
    //   return;
    // }
    //
    //

    if (singleQuestionUserAnswers?.progressBarShown === true) {
      setNextQuestionProgress(0);
      //whatever
    } else if (source !== PlaySource.DAILY) {
      prevQuestionIdx.current = dailyQuestionIdx;
      return;
    } else if (nextQuestionProgress !== 100 && !show_progress_bar) {
      setNextQuestionProgress(0);
    } else if (
      prevQuestionIdx.current !== dailyQuestionIdx &&
      prevQuestionIdx.current !== dailyQuestionIdx &&
      show_progress_bar
    ) {
      setNextQuestionProgress(0);
    } else if (
      show_progress_bar &&
      nextQuestionProgress !== 100 &&
      showUserWasCorrect !== null &&
      prevQuestionIdx.current === dailyQuestionIdx
    ) {
      timeout_ref = setTimeout(() => {
        setNextQuestionProgress(nextQuestionProgress + 5);
      }, PAGE_TURN_TIMER_MS / 22.5);
    }

    return () => {
      if (timeout_ref && prevQuestionIdx.current !== dailyQuestionIdx) {
        clearTimeout(timeout_ref);
        setNextQuestionProgress(0);
      }
      prevQuestionIdx.current = dailyQuestionIdx;
    };
  }, [
    nextQuestionProgress,
    userAnsweredCorrectly,
    gaveUp,
    singleQuestionUserAnswers,
    source,
    show_progress_bar,
    dailyQuestionIdx,
    showUserWasCorrect,
  ]);

  if (!question) {
    return <></>;
  }

  const cannot_guess_anymore =
    singleQuestionUserAnswers?.questionComplete ?? false;

  return (
    <Container
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <div className={styles.question_container}>
        <FlexBox dir="col">
          <div className={styles.question_answer_wrapper}>
            {/* <h2 className={styles.pill_placer}></h2> */}
            <Typography
              variant="h5"
              color="textPrimary"
              className={styles.question}
            >
              {question.question}
            </Typography>
            {/* <hr style={{ width: "100%" }}></hr> */}
            <Box
              sx={{
                position: "relative",
                // left: "15%",
                width: "100%",
                margin: "0px",
                padding: "10px",
              }}
            >
              {show_progress_bar &&
              !singleQuestionUserAnswers?.progressBarShown ? (
                <LinearProgress
                  variant="determinate"
                  value={nextQuestionProgress}
                  // color={
                  //   singleQuestionUserAnswers?.answeredCorrectly === true
                  //     ? "success"
                  //     : "error"
                  // }
                />
              ) : (
                <></>
              )}
              {!show_progress_bar ||
              singleQuestionUserAnswers?.progressBarShown ? (
                <LinearProgress
                  variant="determinate"
                  value={100}
                  // color={
                  //   singleQuestionUserAnswers?.answeredCorrectly === true
                  //     ? "success"
                  //     : singleQuestionUserAnswers?.answeredCorrectly === false
                  //       ? "error"
                  //       : "primary"
                  // }
                />
              ) : (
                <></>
              )}
            </Box>
            <AnswerCard
              source={source}
              question={question}
              singleQuestionUserAnswers={singleQuestionUserAnswers}
              incorrectInputAnimationActive={incorrectInputAnimationActive}
              correctInputAnimationActive={correctInputAnimationActive}
              closeInputAnimationActive={closeInputAnimationActive}
              userAnsweredCorrectly={userAnsweredCorrectly}
              gaveUp={gaveUp}
            />
            <UserGuessInput
              source={source}
              question={question}
              cannot_guess_anymore={cannot_guess_anymore}
              showUserWasCorrect={showUserWasCorrect}
              inputAns={inputAns}
              setInputAns={setInputAns}
              inputRef={inputRef}
              answerEntered={answerEntered}
              incorrectInputAnimationActive={incorrectInputAnimationActive}
              correctInputAnimationActive={correctInputAnimationActive}
              closeInputAnimationActive={closeInputAnimationActive}
              checkAnswer={checkAnswer}
              inputPlaceholder={inputPlaceholder}
              setInputPlaceholder={setInputPlaceholder}
              gaveUp={gaveUp}
            />
            <AnswerGuessStack
              source={source}
              question={question}
              singleQuestionUserAnswers={singleQuestionUserAnswers}
              incorrectInputAnimationActive={incorrectInputAnimationActive}
              correctInputAnimationActive={correctInputAnimationActive}
              closeInputAnimationActive={closeInputAnimationActive}
            />
            {cannot_guess_anymore ? (
              <></>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "row",
                    // maxWidth: "50vw",
                    marginTop: 40,
                  }}
                >
                  {/* <StandardButton
                    fx={handleHintButtonClick}
                    color={"primary"}
                    disabled={singleQuestionUserAnswers?.hintAcitvated ?? false}
                  >
                    Show hint
                  </StandardButton> */}
                  <Button
                    onClick={handleHintButtonClick}
                    color={"primary"}
                    variant={"contained"}
                    disabled={singleQuestionUserAnswers?.hintAcitvated ?? false}
                    // style={{ maxWidth: "10vw" }}
                  >
                    Show hint
                  </Button>
                  <div style={{ width: 40 }}></div>
                  {/* <StandardButton
                    fx={handleGaveUpButtonClick}
                    color={"primary"}
                    disabled={isGiveUpDisabled}
                  >
                    Give up!
                  </StandardButton> */}
                  <Button
                    onClick={handleGaveUpButtonClick}
                    color={"primary"}
                    variant={"contained"}
                    disabled={isGiveUpDisabled}
                    // style={{ maxWidth: "10vw" }}
                  >
                    Give up!
                  </Button>
                </div>
                <br />
                <div className={styles.answer_toggle_section}>
                  {((singleQuestionUserAnswers?.hintAcitvated ?? false) &&
                    source === PlaySource.DAILY) ||
                  (showHint && source === PlaySource.RANDOM) ? (
                    <Typography variant="h5" color="textSecondary">
                      Hint: {question.hint}
                    </Typography>
                  ) : (
                    <>
                      <br />
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </FlexBox>
      </div>
    </Container>
  );
}
