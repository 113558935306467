import * as React from "react";
// import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import styles from "./Hero.module.css";
import { styled } from "@mui/material/styles";
import NewsletterSignup from "../reusables_components/NewsletterSignup";

// const StyledBox = styled("div")(({ theme }) => ({
//   alignSelf: "center",
//   width: "100%",
//   // maxWidth: "1000px",
//   // maxWidth: "800px",
//   justifyContent: "center",
//   // height: 400,
//   height: "100%",
//   // maxHeight: "625px",

//   // marginTop: theme.spacing(8),
//   borderRadius: theme.shape.borderRadius,
//   outline: "6px solid",
//   outlineColor: "hsla(220, 25%, 80%, 0.2)",
//   border: "1px solid",
//   borderColor: theme.palette.grey[200],
//   // boxShadow: "0 0 12px 8px hsla(220, 25%, 80%, 0.2)",
//   backgroundImage: "url(media/hero.png)",
//   backgroundSize: "cover",
//   // [theme.breakpoints.up("sm")]: {
//   //   marginTop: theme.spacing(10),
//   //   // height: "50vh",
//   // },
//   ...theme.applyStyles("dark", {
//     boxShadow: "0 0 24px 12px hsla(210, 100%, 25%, 0.2)",
//     backgroundImage: "inherit",
//     outlineColor: "hsla(220, 20%, 42%, 0.1)",
//     borderColor: theme.palette.grey[700],
//     zIndex: -100,
//     // background: "inherit",
//     filter: "blur(20px)",
//   }),
// }));

// const OuterBoxPost = styled("div")(({ theme }) => ({
//   alignSelf: "center",

//   width: "80vw",
//   maxWidth: "1000px",
//   // maxWidth: "800px",
//   justifyContent: "center",
//   // height: 400,
//   height: "50vw",
//   maxHeight: "625px",

//   position: "relative",
//   top: "-50vw",

//   // borderColor: theme.palette.grey[200],
//   backgroundImage: "url(media/hero.png)",
//   backgroundSize: "cover",
//   // zIndex: 100,
// }));

const OuterBox = styled("div")(({ theme }) => ({
  alignSelf: "center",

  width: "80vw",
  maxWidth: "1000px",
  // maxWidth: "800px",
  justifyContent: "center",
  // height: 400,
  height: "50vw",
  maxHeight: "625px",

  position: "relative",
  top: "0",
  borderRadius: theme.shape.borderRadius,
  // outline: "6px solid",
  // outlineColor: "hsla(220, 25%, 80%, 0.2)",
  border: "2px solid",
  // borderColor: theme.palette.grey[200],
  // boxShadow: "0 0 12px 8px hsla(220, 25%, 80%, 0.2)",
  backgroundImage: "url(media/hero.png)",
  backgroundSize: "cover",
  // zIndex: 100,

  ...theme.applyStyles("dark", {
    boxShadow: "0 0 24px 12px hsla(210, 100%, 25%, 0.2)",
    backgroundImage: "url(media/hero.png)",
    outlineColor: "hsla(220, 20%, 42%, 0.1)",
    borderColor: theme.palette.grey[700],
    zIndex: 100,

    // background: "inherit",
    // filter: "blur(20px)"
  }),
}));

const ImageWrapper = styled("div")(({ theme }) => ({
  alignSelf: "center",
  width: "80vw",
  maxWidth: "1000px",
  // maxWidth: "800px",
  justifyContent: "center",
  // height: 400,
  height: "50vw",
  maxHeight: "625px",

  marginTop: theme.spacing(8),
  // borderRadius: theme.shape.borderRadius,
  // outline: "6px solid",
  // outlineColor: "hsla(220, 25%, 80%, 0.2)",
  // border: "1px solid",
  // borderColor: theme.palette.grey[200],
  // boxShadow: "0 0 12px 8px hsla(220, 25%, 80%, 0.2)",
  backgroundSize: "cover",
  // zIndex: 100,
}));

export default function Hero() {
  return (
    // <Box
    //   id="hero"
    //   sx={(theme) => ({
    //     width: '100%',
    //     backgroundRepeat: 'no-repeat',

    //     backgroundImage:
    //       'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
    //     ...theme.applyStyles('dark', {
    //       backgroundImage:
    //         'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
    //     }),
    //   })}
    // >
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pt: { xs: 14, sm: 20 },
        pb: { xs: 8, sm: 12 },
      }}
    >
      <Stack
        spacing={2}
        useFlexGap
        sx={{ alignItems: "center", width: { xs: "100%", sm: "70%" } }}
      >
        <Typography
          variant="h1"
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            fontSize: "clamp(3rem, 10vw, 3.5rem)",
          }}
        >
          Ask&nbsp;only&nbsp;the&nbsp;
          <Typography
            component="span"
            variant="h1"
            sx={(theme) => ({
              fontSize: "inherit",
              color: "secondary.light",
              ...theme.applyStyles("dark", {
                color: "primary.dark",
              }),
            })}
          >
            best
          </Typography>
        </Typography>

        <NewsletterSignup
          CTA_text={
            "Sign up to learn more about the Trivia Owl, the world's largest and most performant trivia API."
          }
          button_text={"Signup"}
        />
      </Stack>
      {/* <img src="media/hero.png" alt="/public/media/hero.png"></img> */}
      {/* <OuterBox id="background" > */}
      <ImageWrapper>
        <OuterBox id="image" className={styles.original} />
        {/* <OuterBoxPost id="image" className={styles.backlight} /> */}
      </ImageWrapper>
      {/* </OuterBox> */}
    </Container>
    // </Box>
  );
}
