import * as React from "react";
import Container from "@mui/material/Container";
import styles from "./PlayContainer.module.css";
import PlayCardDaily from "./PlayCardDaily";
import PlayCardRandom from "./PlayCardRandom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux_v2/store";
import { Box } from "@mui/material";
import {DailyQuizFinishedUpsell} from "../daily_quiz_finished_components/DailyQuizFinishedUpsell";
import DailyQuizTutorialDialog from "../daily_quiz_finished_components/DailyQuizTutorialDialog";

// const StyledBox = styled("div")(({ theme }) => ({
//   alignSelf: "center",
//   width: "100%",
//   height: "500px",
//   marginTop: theme.spacing(8),
//   borderRadius: theme.shape.borderRadius,
//   outline: "6px solid",
//   outlineColor: "hsla(220, 25%, 80%, 0.2)",
//   border: "1px solid",
//   borderColor: theme.palette.grey[200],
//   boxShadow: "0 0 12px 8px hsla(220, 25%, 80%, 0.2)",
//   backgroundImage: "url(media/hero.png)",
//   backgroundSize: "cover",
//   [theme.breakpoints.up("sm")]: {
//     marginTop: theme.spacing(10),
//     height: 700,
//   },
//   ...theme.applyStyles("dark", {
//     boxShadow: "0 0 24px 12px hsla(210, 100%, 25%, 0.2)",
//     backgroundImage: "url(media/hero.png)",
//     outlineColor: "hsla(220, 20%, 42%, 0.1)",
//     borderColor: theme.palette.grey[700],
//   }),
// }));

export default function PlayContainer() {
  const [votedQs, setVotedQs] = React.useState<Set<string>>(new Set());

  const { progressToFreePlay, isQuizFinished } = useSelector(
    (state: RootState) => state.dailyTrivia
  );
  const { hasSeenTutorial } = useSelector(
    (state: RootState) => state.globalState
  );

  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: "white",
        bgcolor: "primary",
      }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
        className={
          progressToFreePlay
            ? styles.random_page_sizing
            : styles.daily_page_sizing
        }
      >
        <div
          style={{
            alignSelf: "center",
            width: "100%",
            // height: 400,
            // marginTop: theme.spacing(8),
            borderRadius: 30,
            // outline: "6px solid",
            // outlineColor: "hsla(220, 25%, 80%, 0.2)",
            // border: "1px solid",
            // borderColor: theme.palette.grey[200],
            // backgroundColor: "#1a3044",
            // boxShadow: "0 0 12px 8px hsla(220, 25%, 80%, 0.2)",
            backgroundSize: "cover",
            // [theme.breakpoints.up("sm")]: {
            //   marginTop: theme.spacing(10),
            //   height: 700,
            // },
            // ...theme.applyStyles("dark", {
            //   boxShadow: "0 0 24px 12px hsla(210, 100%, 25%, 0.2)",
            //   backgroundImage: "url(media/hero.png)",
            //   outlineColor: "hsla(220, 20%, 42%, 0.1)",
            //   borderColor: theme.palette.grey[700],
            // }),
          }}
        >
          <br />
          <br />
          <>
            {hasSeenTutorial === false ? (
              <DailyQuizTutorialDialog />
            ) : isQuizFinished && !progressToFreePlay ? (
              <DailyQuizFinishedUpsell />
            ) : progressToFreePlay ? (
              <PlayCardRandom votedQs={votedQs} setVotedQs={setVotedQs} />
            ) : (
              <PlayCardDaily votedQs={votedQs} setVotedQs={setVotedQs} />
            )}
          </>
        </div>
      </Container>
    </Box>
  );
}
