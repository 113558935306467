import { Answer } from "../../../redux_v2/dailyTriviaSlice";

export const tutorial_dummy_user_data = [
    {
      questionId: "2nhrzwelkulzt5bb",
      questionIdx: 0,
      answerGuesses: [
        {
          answerText: "rio de janiero",
          isCorrect: 1,
          hintShown: true,
        },
        {
          answerText: "rio de ljhdjkhfs",
          isCorrect: 0,
          hintShown: true,
        },
        {
          answerText: "Revealed Hint",
          isCorrect: 2,
          hintShown: true,
        },
        {
          answerText: "sdfsdf",
          isCorrect: -1,
          hintShown: false,
        },
      ],
      questionComplete: true,
      progressBarShown: false,
      answeredCorrectly: true,
      progressToFreePlay: false,
      hintAcitvated: true,
      closestAnswer: "Rio de Janeiro",
    },
    {
      questionId: "g3mdpyotnvxpmcpy",
      questionIdx: 1,
      answerGuesses: [
        {
          answerText: "Glutious",
          isCorrect: 1,
          hintShown: false,
        },
      ],
      questionComplete: true,
      progressBarShown: false,
      answeredCorrectly: true,
      progressToFreePlay: false,
      hintAcitvated: false,
      closestAnswer: "Gluteus",
    },
    {
      questionId: "ntlbnz26m35ti3ec",
      questionIdx: 2,
      answerGuesses: [
        {
          answerText: "sdjkhf",
          isCorrect: -1,
          hintShown: true,
        },
        {
          answerText: "sdkjfh",
          isCorrect: -1,
          hintShown: true,
        },
        {
          answerText: "Revealed Hint",
          isCorrect: 2,
          hintShown: true,
        },
      ],
      questionComplete: true,
      progressBarShown: false,
      answeredCorrectly: false,
      progressToFreePlay: false,
      hintAcitvated: true,
      closestAnswer: null,
    },
    {
      questionId: "t62crvgp5rhr7yjc",
      questionIdx: 3,
      answerGuesses: [
        {
          answerText: "battle of midway",
          isCorrect: 1,
          hintShown: false,
        },
        {
          answerText: "midway",
          isCorrect: 0,
          hintShown: false,
        },
      ],
      questionComplete: true,
      progressBarShown: false,
      answeredCorrectly: true,
      progressToFreePlay: false,
      hintAcitvated: false,
      closestAnswer: "Battle of Midway",
    },
    {
      questionId: "a5xxvak22xczvida",
      questionIdx: 4,
      answerGuesses: [
        {
          answerText: "kljlkjsdf",
          isCorrect: -1,
          hintShown: true,
        },
        {
          answerText: "ljhsdf",
          isCorrect: -1,
          hintShown: true,
        },
        {
          answerText: "Revealed Hint",
          isCorrect: 2,
          hintShown: true,
        },
        {
          answerText: "lhlkjhsd",
          isCorrect: -1,
          hintShown: false,
        },
      ],
      questionComplete: true,
      progressBarShown: false,
      answeredCorrectly: false,
      progressToFreePlay: false,
      hintAcitvated: true,
      closestAnswer: null,
    },
  ];
  
  export const tutorial_dummy_wordle_string = ["⬜💡🟨🟩", "🟩", "💡⬜⬜⬛", "🟨🟩", "⬜💡⬜⬜"];
  
  export const tutorial_dummy_answer_stack: Answer[] = [
    {
      answerText: "Rio de Janiero",
      isCorrect: 1,
      hintShown: false,
    },
  
    {
      answerText: "São José do Rio Preto",
      isCorrect: 0,
      hintShown: false,
    },
    {
      answerText: "Revealed Hint",
      isCorrect: 2,
      hintShown: true,
    },
    {
      answerText: "Sao Paulo",
      isCorrect: -1,
      hintShown: false,
    },
  ];